
import React from 'react';
import './Hero.css';
import illustration from '../Images/bro.png'; 
import { Link } from 'react-router-dom';
const HeroSection = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <div style={{width:'390px'}}>
        <h1><span style={{color: 'rgba(55, 54, 86, 1)'}}>Empowering Sustainability:</span>  Global's Leading ESG Data Lake Platform</h1></div>
        <div className="hero-buttons">
          <Link to = "/data-lake/news" ><button className="explore-news">Explore News</button></Link>
          <Link to ="/Contact" ><button className="contact-usss">Contact Us</button></Link>
        </div>
      </div>
      <div className="hero-illustration">
        <img src={illustration} alt="Sustainable Data Illustration" />
      </div>
    </section>
  );
};

export default HeroSection;