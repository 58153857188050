
const dataSheets = [
    {
        "Title": "Abu Dhabi Investment Office ESG Policy",
        "Description": "ADIO's ESG Policy aims to align its programmes with UAE's environmental and social goals, emphasizing sustainability and global best practices. It outlines expectations for private sector partners in projects like PPP and Incentive Programmes, promoting ESG integration and innovation. ADIO prioritizes long-term sustainability to support Abu Dhabi's economic diversification with strong ESG principles across its operations.",
        "Link": "https://drive.google.com/file/d/1iZKjakuSLYzezSBdrCmYVXFr-d5QMvA6/view"
    },
    {
        "Title": "ADX ESG Disclosure Guidance",
        "Description": "Abu Dhabi Securities Exchange ADX has made a formal commitment to drive sustainability in financial markets. The voluntary guidance provides ADX listed companies with 31 ESG indicators that are considered essential to report in alignment with the recommendations of the Sustainable Stock Exchanges SSE Initiative and the World Federation of Exchanges WFE.",
        "Link": "https://drive.google.com/file/d/1ZLRdQQCI3wfxP7g8tRR6FtGuBpfoQn28/view?usp=drive_link"
    },
    {
        "Title": "Attention to sustainability and ESG",
        "Description": "The current Corporate Governance Code requires listed companies to take appropriate measures to address sustainability issues. The last revision of the Stewardship Code March 2020 added the consideration of sustainability medium to longterm sustainability including ESG factors. This Code defines principles considered to be helpful for institutional investors who behave as responsible institutional investors.",
        "Link": "https://drive.google.com/file/d/1ecAGRhvdp51dP8uKweHr1uIsZngcPjeb/view?usp=drive_link"
    },
    {
        "Title": "Briefing on Sustainable Finance Policy in Japan",
        "Description": "Sustainable finance has become key for Japan to increase its attractiveness as a global financial market. Japan as the worlds third largest economy is expected to make a substantial contribution to addressing global challenges such as climate change and inequality. The way the investment policy framework is designed and implemented affects the ability of institutional investors to generate sustainable returns and create value over the long term.",
        "Link": "https://drive.google.com/file/d/1yD3MA49mE_F8IK_t9NfnYmFS-gYQxEfP/view?usp=drive_link"
    },
    {
        "Title": "Bringing ESG considerations to Australian strategies",
        "Description": "The S&P/ASX 200 ESG Index uses S&P DJI ESG Scores to select sustainable companies, excluding those involved in thermal coal, tobacco, and controversial weapons, aiming for improved sustainability profiles compared to its benchmark. The S&P Developed Ex-Australia LargeMidCap and S&P Emerging LargeMidCap Carbon Control Indices focus on reducing carbon intensity, excluding firms with significant involvement in fossil fuels, tobacco, and controversial weapons, among others. The Dow Jones Global Select ESG Real Estate Securities Index incorporates GRESB data to enhance sustainability within the real estate sector, applying similar exclusions and emphasizing ESG best practices.",
        "Link": "https://drive.google.com/file/d/1IEEyE8RyFhzW6RB6eP50WJVRg-V2twhj/view?usp=drive_link"
    },
    {
        "Title": "British Columbia ESG Supplementary Data Report",
        "Description": "British Columbia is focused on building a strong expanding economy where environmental and social sustainability is the basis for future growth. Our StrongerBC Economic Plan is designed to meet the challenges we face today by achieving two big goals that align with ESG principles. Clean and inclusive growth to create a more prosperous BC for generations to come.",
        "Link": "https://drive.google.com/file/d/1EuKleSreGUVfbYlLBWwchXZZCG-OVecn/view?usp=drive_link"
    },
    {
        "Title": "Canadian ESG Market Review",
        "Description": "DPA ESG Insights series delves into the latest trends data and issues related to Environmental Social and Governance ESG investing. In this edition we present an update on the progress of ESG in Canada with greater focus Net Zero and climate goals. We will focus more on the plans and actions of the asset management sector our primary customer set. We also briefly review the corporate and government sector.",
        "Link": "https://drive.google.com/file/d/1ac9DXPAzQ-T2-dmx8XJBI_Vbd9ocuGng/view?usp=drive_link"
    },
    {
        "Title": "Challenges in ESG Disclosures and Integration in China",
        "Description": "The global response to the corona virus crisis has highlighted the importance of how companies respond to social and environmental issues. Chinese regulators begin to detail the mandatory disclosure requirements for listed companies on their environmentrelated information. ESG awareness monitoring and disclosure in China are bound to accelerate. However Chinese corporates will be challenged to meet stricter regulations.",
        "Link": "https://drive.google.com/file/d/1nrK4RAXGyEcd3SDWM2_b9OjP7GFss2mv/view?usp=drive_link"
    },
    {
        "Title": "Chinese Firms and Adherence to Global ESG Standards in Developing Countries",
        "Description": "Chinese firms and adherence to global Environmental Social and Governance ESG standards in developing countries is there potential to create common ground. The views expressed in this paper are those of the authors and do not necessarily reflect the views or policies of the German Institute of Development and Sustainability IDOS. This publication is licensed under Creative Commons Attribution CC BY 40.",
        "Link": "https://drive.google.com/file/d/1o3_yzuYBk8lZE8_h6Hp4KRgL8eu0Nuf6/view?usp=drive_link"
    },
    {
        "Title": "CWF ESG and Government Report",
        "Description": "ESG reporting has surged globally and in Canada, impacting businesses and investors significantly. Canadian governments must grasp ESG's alignment with environmental protection and regulatory roles, consider regulating ESG disclosures, and leverage ESG to attract investment. They face challenges in how ESG ratings influence credit ratings and financing, necessitating careful evaluation of actionable steps amidst potential pitfalls.",
        "Link": "https://drive.google.com/file/d/1f_HyUAJ7Ef7jCJFYewaD_Q6uSN7kOVi5/view?usp=drive_link"
    },
    {
        "Title": "Residential ESG Guidance",
        "Description": "ESG serves two chief purposes it acts as a tool to mitigate risk from the known and unknown future and build resilience to these risks and a framework within which real estate can create positive environmental and social change. Our society is headed for a climate crisis which is already arriving at an increasing speed according to the Intergovernmental Panel on Climate Change IPCC. The Covid19 pandemic has clearly showed that our built environment must create positive change for the people.",
        "Link": "https://drive.google.com/file/d/1-qMZSsEHlOwiiFdR3gkLnFgvjm9gUv7U/view?usp=drive_link"
    },
    {
        "Title": "Environmental Social and Governance ESG and Sustainable Development the legal and regulatory framework in Ireland",
        "Description": "This Spotlight examines the legal and regulatory framework concerning ESG and sustainable development in Ireland. It considers the importance of ESG for key stakeholders and discusses legislative and policy developments at the EU and domestic levels. It identifies supports for companies transitioning to ESG friendly practices and compares Irelands performance against other EU and OECD Member States.",
        "Link": "https://drive.google.com/file/d/1IVWvi87M5YkcbHtOQc9u0zSmW9ux9mC-/view?usp=drive_link"
    },
    {
        "Title": "Environmental Social Governance Brochure",
        "Description": "NSW is positioning itself as a leading destination for critical minerals and high-tech metals, emphasizing robust ESG protections and regulatory frameworks. The state boasts significant deposits of minerals essential for global decarbonization efforts, supported by comprehensive environmental, social, and governance (ESG) integration in its development pathways. NSW demonstrates strong compliance with international standards like ICMM and IRMA, ensuring sustainability and ethical practices across its mining sector.",
        "Link": "https://drive.google.com/file/d/1XLVZHqpTGSvUkoIB67SJBT23NCfkjHG6/view?usp=drive_link"
    },
    {
        "Title": "Environmental, Social & Governance ESG Policy",
        "Description": "The ESG Policy of SRILIAC, part of the Swiss Re Group, aligns with the UN Sustainable Development Goals (SDGs) and aims to integrate sustainability into its investment strategies. It emphasizes transparency under EU Regulation 2019/2088 to disclose sustainability-related information, focusing on environmental, social, and governance criteria to manage risks and seize opportunities.",
        "Link": "https://drive.google.com/file/d/1WVKM96tASWocVYzzJdGPwud-DRepLxV8/view?usp=drive_link"
    },
    {
        "Title": "Environmental, Social & Governance Law 2021",
        "Description": "The UK's ESG regulatory framework is complex and diverse, comprising various laws and regulations derived from both domestic and EU sources. Key components include the UK Corporate Governance Code, directors' duties under the Companies Act, climate change obligations under the Climate Change Act 2008, and provisions from acts such as the Bribery Act 2010 and Modern Slavery Act 2015. The impending transition to the Audit, Reporting, and Governance Authority (ARGA) aims to enhance oversight and corporate accountability in the UK's governance landscape.",
        "Link": "https://drive.google.com/file/d/1yxwMvGDNiq6JL2--Zxo0ouCfJyrAUtiV/view?usp=drive_link"
    },
    {
        "Title": "Environmental, Social & Governance Law",
        "Description": "Practical crossborder insights into ESG lawSecond Edition. Environmental Social Governance Law 2022. Practical cross border insights intoESG law. ESG and UK Pension Schemes A Matter of Governance. Shareholders in Shaping ESG Strategy. The role of shareholders in shaping ESG strategy.",
        "Link": "https://drive.google.com/file/d/1KS6EATNrC7eMUgZtmfjvGxgRYBFIRJ8E/view?usp=drive_link"
    },
    {
        "Title": "Environmental, Social, and Governance (ESG) Policy",
        "Description": "The purpose of the ESG Policy is to define the companys commitments and requirements to make a positive contribution to economic environmental and social progress through its business. Fluidra is convinced that a company with high environmental social and corporate governance ESG standards is not only more economically sustainable but also highly valued by its stakeholders.",
        "Link": "https://drive.google.com/file/d/1CnHivMOsYuU2cu491lEic_Oc6VPWHJml/view?usp=drive_link"
    },
    {
        "Title": "Environmental, Social, Governance (ESG) Report",
        "Description": "Our ESG strategy focuses on the consumer driving financial inclusion ensuring a brighter financial future for all through the power of data our data. We have made progress in our sustainability journey with a successful migration to the cloud and investment in new facilities to reduce our impact on the environment. However we are on a journey There is much still to do to build momentum in creating lasting and purposeful change.",
        "Link": "https://drive.google.com/file/d/1mDoouNWVQ8vNGJOfs5nol38IwurPBsIo/view?usp=drive_link"
    },
    {
        "Title": "ESG 2023 Germany",
        "Description": "The regulatory landscape in Germany for sustainable finance includes the direct applicability of the Sustainable Finance Disclosure Regulation (SFDR) and Taxonomy Regulation, while directives like the Non-Financial Reporting Directive (NFRD) and Corporate Sustainability Reporting Directive (CSRD) require transposition into national law. Germany implemented the NFRD through the CSR-Richtlinie-Umsetzungsgesetz, and is preparing for the CSRD's potential adoption. The Corporate Sustainability Due Diligence Directive (CSDD) aligns with Germany's existing Supply Chain Act, with proposed amendments expected to integrate new climate-related obligations.",
        "Link": "https://drive.google.com/file/d/1iHonnAsDhecgdT4eAigs7I9RWYZGwmuS/view?usp=drive_link"
    },
    {
        "Title": "ESG and Impact Investing",
        "Description": "Japan has committed to SDGs and the Paris Agreement, targeting a 26% reduction in greenhouse gas emissions by 2030 and achieving carbon neutrality by 2050. The government promotes ESG financing and impact investing through policies like the Grand Design for New Form of Capitalism and GX Implementation Council, emphasizing sustainability and human rights in business practices and supply chains. Fiduciary duties of institutional investors do not legally mandate ESG consideration but allow discretion for long-term economic benefits.",
        "Link": "https://drive.google.com/file/d/14PFzbt-uX7VdWWwcEJ1ap6lCsQ3yEusV/view?usp=drive_link"
    },
    {
        "Title": "ESG Comparative Guide Australia",
        "Description": "Australia's regulatory approach to ESG issues is decentralized, with federal and state jurisdictions each managing diverse environmental commitments, including net zero emissions targets and renewable energy goals.",
        "Link": "https://drive.google.com/file/d/1TxdN3Ybf_5PcuD_usBPBUr76H7PImrsV/view?usp=drive_link"
    },
    {
        "Title": "ESG Disclosure Policy design for China",
        "Description": "This briefing provides technical guidance as part of the PRI's work on ESG disclosure in China. The PRI has over 2500 signatories pension funds insurers investment managers and service providers globally with approximately US 85 trillion in assets under management. Responsible investment acknowledges the relevance to the investor of environmental social and governance ESG factors in investment decisionmaking for the longterm health and stability of financial markets.",
        "Link": "https://drive.google.com/file/d/1OrCIC1GYxYpE8uhGNY6Ib1T6PVVp4FZH/view?usp=drive_link"
    },
    {
        "Title": "ESG Government Leadership Catalyst for Success",
        "Description": "ESG refers to a framework that integrates environmental social and governance risks and opportunities into an organizations strategy. The ESG movement has quickly evolved from socially responsible investing to a global imperative affecting not only investment decisions and shareholders. For governments the goal is to build longterm sustainability and achieve desired outcomes for constituents.",
        "Link": "https://drive.google.com/file/d/1Y7BI-LQxv8HZKm3N_us6eIY7rnkODDRv/view?usp=drive_link"
    },
    {
        "Title": "ESG Guide Germany",
        "Description": "A large proportion of ESG regulation in Germany is based on EU law. There is a variety of national ESG regulations in particular focusing on sustainability climate change renewable energy andgender equality. It is impossible to name all German regulations that have an ESG angle to them.",
        "Link": "https://drive.google.com/file/d/1rrlg8T2k6wAzK92Od32Wy9h4eSLNUyzI/view?usp=drive_link"
    },
    {
        "Title": "ESG in Canada",
        "Description": "ESG in Canada What We Learned in 2021 and Looking Ahead to 2022. Report offers companies and business leaders in Canada an essential overview of the ESG developments in regulations laws and norms that have occurred over the last year. Report also highlights issues to watch as we enter 2022 Many of these developments will reshape Canadas business landscape for decades to come.",
        "Link": "https://drive.google.com/file/d/1_bkNT9oVRQKaHy8rKcZ8Cs-51PLgZstN/view?usp=drive_link"
    },
    {
        "Title": "ESG Initiatives - Data Book",
        "Description": "We will contribute to environmental conservation and the sustainable development and prosperity of the economy industry and society both in Japan and around the world. We will engage in management practices that take into consideration value creation for various stakeholders and will work to improve our corporate value through the sustainable and steady growth of the Mizuho group.",
        "Link": "https://drive.google.com/file/d/1zi47Mxf0_r2P9lbJDjJ7pUcBTuj8_Lb3/view?usp=drive_link"
    },
    {
        "Title": "ESG Laws Across the World",
        "Description": "ESG regulations are government standards for ESGrelated actions reporting or disclosures. While the ESG space is mostly unregulated various legal requirements have already passed in some jurisdictions with the EU leading in this regard. Some businesses may already be undertaking ESG reporting and disclosures depending on their size and industry.",
        "Link": "https://drive.google.com/file/d/1zyGsEq-INO3J8q4uroeo4IMqhusaNEu9/view?usp=drive_link"
    },
    {
        "Title": "ESG Practices in ASEAN & Korea Pathways Towards Sustainability",
        "Description": "The ASEAN-Korea Centre, focused on promoting trade and investment between ASEAN and Korea, has published a guidebook titled \"ESG Practices in ASEAN and Korea: Pathways Towards Sustainability.\" This comprehensive guide aims to navigate and enhance understanding of environmental, social, and governance (ESG) practices in both regions. Highlighting the critical role of micro, small, and medium-sized enterprises (MSMEs) in ASEAN's economy, the guidebook addresses the challenges they face in adopting ESG principles due to knowledge gaps. It seeks to facilitate ESG cooperation between ASEAN and Korea, offering insights for policymakers, investors, and consumers to integrate sustainable practices effectively.",
        "Link": "https://drive.google.com/file/d/1YoazBaX-JD4-Fo7t8LH1tvAIm46OU4_Y/view?usp=drive_link"
    },
    {
        "Title": "ESG Priorities for UK Companies",
        "Description": "For 2022 the IoD has identified a number of ESG priorities for UK companies and organisations. They are applicable to most entities although the manner in which they are implemented will vary according to the size complexity and sector of each individual enterprise. These priorities draw on the findings of IoD Global Conference 2021 Connecting the World.",
        "Link": "https://drive.google.com/file/d/1NpusR8DVvdwL39eOIemSdVmu1gyId0ni/view?usp=drive_link"
    },
    {
        "Title": "ESG Progress Report",
        "Description": "The RBC ESG Progress Report, informed by international standards, covers 2023 fiscal year data and key performance indicators, excluding RBC Brewin Dolphin pre-acquisition. PwC provided limited assurance on selected indicators.",
        "Link": "https://drive.google.com/file/d/1ny6CtEDR3xr9cEOeG-sI-wfJSbxXkYkk/view?usp=drive_link"
    },
    {
        "Title": "ESG Regulation and Implementation in Canada",
        "Description": "ESG regulation and implementation in Canada arise from many different statutes. Canada does not have a specific and comprehensive ESGdirected code or statute. Rather ESGrelated regulatory obligations are imposed through multiple statutes aswell as common law requirements. ESG legislation from both levels of government as well as municipalgovernments and Indigenous governing bodies.",
        "Link": "https://drive.google.com/file/d/1dN3aXqSJuMJXE4aBjXwi7dFiD7GB0__P/view?usp=drive_link"
    },
    {
        "Title": "ESG regulation in Spain",
        "Description": "The current ESG legislation landscape in Spain includes several significant laws and directives such as Law 11/2018 (EU Non-Financial Reporting Directive), Ley de Sociedades de Capital (Corporate Enterprises Act), Law 9/2017 on Public Sector Contracts, Royal Legislative Decree 1/2020, Law 7/2021 on Climate Change and Energy Transition (Ley Cambio Climatico), Royal Decree 564/2017 on energy efficiency in buildings, and the National Integrated Energy and Climate Plan (Plan Nacional Integrado de Energ\u00eda y Clima PNIEC). These regulations collectively aim to enhance non-financial reporting, regulate corporate governance, promote energy efficiency, and set ambitious climate targets for emissions reduction in Spain.",
        "Link": "https://drive.google.com/file/d/1kcZPRnidcX-mGOYYiXVenlTw4yVPAw-8/view?usp=drive_link"
    },
    {
        "Title": "ESG Regulation in Switzerland",
        "Description": "KPMG Law Switzerland provides extensive legal support in navigating ESG compliance challenges, including climate risk management, supply chain sustainability, shareholder activism, and board accountability. They assist companies in Switzerland and globally to adhere to evolving ESG regulations, ensuring legal adherence and mitigating risks effectively.",
        "Link": "https://drive.google.com/file/d/1mtfyskRdnIzX0yxeEZZF5Jwxk4W9iFgz/view?usp=drive_link"
    },
    {
        "Title": "ESG Report",
        "Description": "First Abu Dhabi Bank FAB embraces its role in steering the banking community towards a more sustainable and equitable future. As part of this commitment we have been disclosing our sustainability performance across FAB Group annually since 2009. This ESG report describes our success and achievements across 2023 and highlights the ambitious future targets we have set.",
        "Link": "https://drive.google.com/file/d/1t1lfYWKhrYP2E89vBWLS4ax5tnt_XoYu/view?usp=drive_link"
    },
    {
        "Title": "ESG Standards, Regulations, and Implementation",
        "Description": "The ISLP-developed ESG Guide, supported by the Gordon & Betty Moore Foundation, offers insights into ESG trends and legal developments across Australia, Canada, the EU, UK, US, Peru, Colombia, and Brazil. It emphasizes understanding how environmental, social, and governance factors impact financial performance and stakeholder engagement, influencing company operations and investment strategies globally.",
        "Link": "https://drive.google.com/file/d/1fj1BYrRp50E3Zu7pwbH83J4zVv7fHOkR/view?usp=drive_link"
    },
    {
        "Title": "ESG Regulatory and Policy Tracker",
        "Description": "The ESG Regulatory and Policy Tracker outlines key federal and state ESG laws, rules, and policies, highlighting major federal policies and providing links to source materials. It details the implementation functions and procedures of Chapter 8, focusing on sustainability legislation and regulation. This document serves as a comprehensive guide for understanding the evolving ESG regulatory landscape.",
        "Link": "https://drive.google.com/file/d/1J-nJxqdbHtqzy4VFspM4E1Ik_u_NmcbU/view?usp=drive_link"
    },
    {
        "Title": "Future of ESG by 2043 in Canada and Potential Implications on Large and Public Companies",
        "Description": "ESG, or Environmental, Social, and Governance, evolved from historical efforts to promote socially engaged organizations dating back to the 19th century. Coined in a 2005 UN report, ESG aims to embed these factors into capital markets for sustainable business practices. Today, frameworks like the UN Principles for Responsible Investment guide ESG integration, where companies disclose operational impacts to stakeholders, enhancing reputational value and attracting investors focused on sustainability and risk management.",
        "Link": "https://drive.google.com/file/d/1wo4cKqHMVONLpJlTbEOkdaOfSXlOWZ0P/view?usp=drive_link"
    },
    {
        "Title": "German Sustainable Development Strategy",
        "Description": "Germany's commitment to sustainable development through multilateralism and international cooperation, as outlined in its approach to implementing the 2030 Agenda, emphasizes partnerships, international agreements, and strengthening global organizations. This includes initiatives in climate change, security, human rights, and youth participation, demonstrated during its tenure on the UN Security Council. Germany's foreign policy underscores sustainability as a cornerstone of global security and development efforts, advocating for multilateral solutions to address pressing global challenges.",
        "Link": "https://drive.google.com/file/d/1h7w9uZfz229Xw0QwV_f_JI5-sRkqI_oK/view?usp=drive_link"
    },
    {
        "Title": "German Sustainable Finance Strategy",
        "Description": "German Sustainable Finance Strategy is focused on financial market policy and regulation. Sustainability risks are also investment risks Hence in the view of the German government financial market stability in itself is an inherent central goal of sustainable finance. Sustainable finance is closely linked to policy areas such as fiscal environmental human rights and development policies.",
        "Link": "https://drive.google.com/file/d/1w_edJEM0kZQYPCasIuZ_76c5I39-ABhG/view?usp=drive_link"
    },
    {
        "Title": "How Germany's New ESG Law Will Affect Suppliers Globally",
        "Description": "Latham Watkins operates worldwide as a limited liability partnership organized under the laws of the State of Delaware USA. The Supply Chain Act obliges inscope companies to identify address and report on human rightsrelated and environmental risks in their supply and value chains. Suppliers to German businesses should familiarize themselves with this legislation and anticipate that their German customers will require ESG related information.",
        "Link": "https://drive.google.com/file/d/1q_Ztpf-qr412WEMnI9fu-TUlxZ_0KziL/view?usp=drive_link"
    },
    {
        "Title": "Japan Sustainable Finance Policy Update",
        "Description": "During early 2022, Japan intensified its focus on sustainable finance with new policies for ESG disclosure, climate transition finance roadmaps by METI, and guidelines for green financial instruments by the MOE. Initiatives like the SSBJ and FSA's Technical Committee aimed to enhance sustainability standards, while events such as the ESG Finance Awards highlighted Japan's commitment to integrating ESG into its financial sector. These efforts underscore Japan's proactive stance in advancing sustainable finance practices.",
        "Link": "https://drive.google.com/file/d/1rnx_pzZg_lD7XOKUE3KF_wxvTuz11knn/view?usp=drive_link"
    },
    {
        "Title": "JBIC ESG Policy",
        "Description": "JBIC promotes initiatives in the environment field to foster sustainable development globally through engagement with national governments, authorities, and multilateral cooperation, focusing on energy transition and the circular economy in emerging and developing countries.",
        "Link": "https://drive.google.com/file/d/1fFw_qLGUpSahfEYEnkQkEF2Z6G2oF5Hb/view?usp=drive_link"
    },
    {
        "Title": "K-ESG Regulatory Outlook",
        "Description": "The newly distributed KESG Guideline prepared 61 common and core points by analyzing 13 major domestic and foreign assessment indicators and disclosure criteria. In January 2021 the Financial Services Commission FSC announced initiatives to promote ESG responsible investing. ESG disclosure will become mandatory in phases for KOSPI entities with assets over two trillion Korean won by 2025.",
        "Link": "https://drive.google.com/file/d/1XcrQEh-9AVAFVKdNhOn4-B6RoPTvK5MX/view?usp=drive_link"
    },
    {
        "Title": "Position Paper on an EU Regulation of ESG Rating Providers",
        "Description": "German Sustainable Finance Advisory Committee Position Paper on an EU Regulation of ESG Rating Providers. Investors banks and other financial market participants around the world as well as companies themselves are increasingly using sustainability or ESG ratings and scores to make investment decisions carry out financial transactions and create benchmarks and comparison groups.",
        "Link": "https://drive.google.com/file/d/1pKe6UBomN3Gd1mr2XKVspht1iEfC_1YD/view?usp=drive_link"
    },
    {
        "Title": "Regulatory Intelligence France ESG reporting",
        "Description": "ESG reporting is crucial in Europe for addressing climate change, sustainability, workplace diversity, and gender pay gaps. Key EU legislation includes the Non-Financial Reporting Directive (NFRD), Shareholder Rights Directive (SRD II), Sustainability-related Financial Disclosures Regulation (SFDR), and the Taxonomy Regulation, which standardize and enhance transparency and sustainability in corporate reporting. France's ESG laws are also outlined by legal experts from Gide Loyrette Nouel.",
        "Link": "https://drive.google.com/file/d/1rvVT3CzOtqYCEbqiOETjEY83f7HwEUHh/view?usp=drive_link"
    },
    {
        "Title": "Responsible Investment Report",
        "Description": "In 2018, the Banque de France adopted a Responsible Investment Charter to integrate climate and ESG factors into its investment policy, applying a double materiality principle to its portfolios. The Bank publishes an annual responsible investment report and, starting in 2022, a climate action report following TCFD recommendations, detailing its climate-related activities and risk management. These documents are publicly available on its website and the Climate Transparency Hub.",
        "Link": "https://drive.google.com/file/d/1Lxoz5A5ksICuYlIW6a44xjCPJcv_7w_n/view?usp=drive_link"
    },
    {
        "Title": "Swiss Re's ESG Risk Framework",
        "Description": "Swiss Re integrates sustainability risks into its risk management through the ESG Risk Framework, aiming to mitigate potential downsides for both its business and society. This framework guides business decisions by providing a comprehensive approach to assessing sustainability risks alongside financial and compliance factors. It applies across Swiss Re Group entities, ensuring robust risk assessment in underwriting and asset management activities, contingent on data availability and operational processes tailored to each area.",
        "Link": "https://drive.google.com/file/d/1K4sRFsJVkE5oC5Tbb4WyZCYNTAjZR6mi/view?usp=drive_link"
    },
    {
        "Title": "The future regulatory regime for Environmental, Social, and Governance (ESG) ratings providers",
        "Description": "ESG factors are increasingly influencing global financial markets, with nearly half of UK's \u00a310 trillion assets under management integrating ESG considerations. This trend is driven by rising consumer demand for sustainable financial products. The UK's commitment to reaching net zero by 2050 has spurred updated Green Finance policies, aiming to channel trillions in investments towards climate resilience and environmental recovery.",
        "Link": "https://drive.google.com/file/d/1jsYQsC3OJpQk0qOyuuAwFYYXwJi4Cw6H/view?usp=drive_link"
    },
    {
        "Title": "The Reporting Exchange An overview of sustainability and corporate reporting in China",
        "Description": "In 2017 the World Business Council for Sustainable Development WBCSD in partnership with the Climate Disclosure Standards Board CDSB and Ecodesk launched the Reporting Exchange. This free online platform has been designed to help business navigate the oftenconfusing world of corporate reporting. The Reporting Exchange summarizes and connects ESG reporting requirements and resources from across 60 countries and 70 sectors.",
        "Link": "https://drive.google.com/file/d/1lK8KGfq1hHhHpVzypU7ab3Qm5A79Dgwt/view?usp=drive_link"
    },
    {
        "Title": "U.S. Regulatory Framework for ESG Disclosures",
        "Description": "The US Regulatory Framework for ESG Disclosures. The SEC takes a principles based materiality focused approach to disclosure. While an increasing number of groups have called on the SEC to be more prescriptive on ESG disclosures. A shift to standards that track for example the recommendations of the Task Force on Climaterelated Financial disclosures TCFD is unlikely in the short term.",
        "Link": "https://drive.google.com/file/d/13iJLKsNHyiEGIEZ4EMwMfC2zImUpd8Va/view?usp=drive_link"
    },
    {
        "Title": "Western Australian Industry Environmental, Social, and Governance Information Pack",
        "Description": "More than ever customers suppliers investors and communities around the world are demanding that businesses strengthen their Environmental Social and Governance ESG commitments. Over 16 trillion worth of ESG bonds being issued globally in 2021 more than twice the previous years issuance Western Australias industries and businesses are leading the way to position our state at the forefront.",
        "Link": "https://drive.google.com/file/d/1gmLYaNQQv_eUMHwHQCzGoSzALEcjeUsA/view?usp=drive_link"
    },
    {
        "Title": "White paper on ESG Practices in China",
        "Description": "Chinas ESG practice is in the ascendancy. More and more enterprises and financial institutions have incorporated environmental and social benefits into their business objectives striving to achieve longterm sustainable development with winwin results for all parties. All good principles should adapt to changing times to remain relevant.",
        "Link": "https://drive.google.com/file/d/1LGxCSH84zILM1JSIdnGOHaeAjI-2DIJ7/view?usp=drive_link"
    }
]

export default dataSheets;