

import React from 'react';
import "../App.css";
import contactImage from "../Components/Images/about.png";
import Footer from '../Components/Footer/Footer';
import BgImage from "./Images/Ellipse 22.png";
import globe from "../Components/Images/Save-earth.png";

const About = () => {
    return (
        <>
            <div className="about-us">
                <div className="what-we-do">
                    <div className="globe-image">
                        <img className = "save" src={globe} alt="Save Earth" />
                    </div>
                    
                    <div className="description">
                        <h2>What we do?</h2>
                        <p>
                            Welcome to our world of expertise in ESG, Sustainability, Data, and AI, where we empower organizations to achieve their ESG goals with precision and excellence.At the heart of our operations is a dynamic and youthful team of ESG Consultants, Data Analysts, and AI Experts dedicated to transforming your ESG aspirations into tangible results. Our multidisciplinary team brings together deep knowledge in sustainability practices and cutting-edge technology, ensuring that we provide comprehensive and innovative solutions tailored to your unique needs.
                        </p>
                    </div>
                </div>
                <div className="global-reach">
                    <h2 className="Title">Our Global Reach</h2>
                    <p className="Description">
                        We proudly serve a diverse clientele around the globe, addressing a wide range of ESG requirements across various industries. From multinational corporations to emerging enterprises, our clients trust us to navigate the complexities of ESG integration, reporting, and compliance.
                    </p>
                </div>
                <div className="key-features">
                    
                    <div className='key-containerx'>
                        <h2>Our Key <span>Commitment!</span></h2>
                        <p className="Description1">
                        We are committed to making a positive impact through our work. By leveraging our expertise in data and AI, we deliver advanced analytics and insights that drive sustainable growth and help you stay ahead in the ever-evolving ESG landscape.Join us on this journey towards a sustainable future. Together, we can achieve your ESG objectives and make a lasting difference. Contact us today to learn how we can help your organization thrive in the realm of ESG and sustainability.
                    </p>
                    </div>
                </div> 
                < div className="contact-US">
                    <h2>Together, we can achieve your ESG objectives and make a lasting difference. <span>Contact us today to learn how we can help your organization thrive in the realm of ESG and sustainability!</span></h2>
                    
                    <div className="contact-image">
                        <img src={contactImage} alt="Contact Us" />
                    </div>
                </div> 
            </div>
            <Footer />
        </> 
    );
};

export default About;
