// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.news-card {
    width: 250px;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px;
  }
  
  .news-card img {
   width: 0px;
    height: 40%;
  }
  .news-card {
    width: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
  }
  
  .news-card-image {
    max-width: 200px;
    height: 200px;
  
  }
  
  .news-card-content {
    padding: 16px;
    text-align: left;
  }
  
  .news-card-title {
    font-size: 1rem;
    margin: 0 0 8px;
  }
  
  .news-card-date {
    font-size: 0.875rem;
    text-align: right;
    color: #888;
    margin: 0 0 5px;
    margin-bottom: -10px;
  }
  
  .news-card-description {
    font-size: 0.8rem;
    color: #333;
    margin: 0;
  }
`, "",{"version":3,"sources":["webpack://./src/Components/Cards/NewsCard/NewsCard.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;EACd;;EAEA;GACC,UAAU;IACT,WAAW;EACb;EACA;IACE,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,wCAAwC;IACxC,0BAA0B;EAC5B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,gBAAgB;IAChB,aAAa;;EAEf;;EAEA;IACE,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,oBAAoB;EACtB;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,SAAS;EACX","sourcesContent":["\n.news-card {\n    width: 250px;\n    border-radius: 10px;\n    overflow: hidden;\n    margin: 20px;\n  }\n  \n  .news-card img {\n   width: 0px;\n    height: 40%;\n  }\n  .news-card {\n    width: 200px;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    overflow: hidden;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: transform 0.3s;\n  }\n  \n  .news-card:hover {\n    transform: translateY(-5px);\n  }\n  \n  .news-card-image {\n    max-width: 200px;\n    height: 200px;\n  \n  }\n  \n  .news-card-content {\n    padding: 16px;\n    text-align: left;\n  }\n  \n  .news-card-title {\n    font-size: 1rem;\n    margin: 0 0 8px;\n  }\n  \n  .news-card-date {\n    font-size: 0.875rem;\n    text-align: right;\n    color: #888;\n    margin: 0 0 5px;\n    margin-bottom: -10px;\n  }\n  \n  .news-card-description {\n    font-size: 0.8rem;\n    color: #333;\n    margin: 0;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
