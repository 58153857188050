
// NewsCard.js
import React from 'react';
import './NewsCard.css';

const NewsCard = ({ Image_URL, Title, Date, Description, className }) => {
  return (
    <div className={`news-card ${className}`}>
      <img src={Image_URL} alt={Title} className="news-card-image" />
      <div className="news-card-content">
        <h3 className="news-card-title">{Title}</h3>
        <p className="news-card-description">{Description}</p>
        <p  className="news-card-date">{Date}</p>
      </div>
    </div>
  );
};

export default NewsCard;
