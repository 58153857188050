import axios from 'axios';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import "../App.css";
import Footer from '../Components/Footer/Footer';
import Backimg from "../Components/Images/image 4.png";
import Loader from "../Components/Loader/loader";

const ESGAssetCard = lazy(() => import('../Components/Cards/ESGAssetCard/ESGAssetCard'));
const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

const DataLakeNews = () => {
    const [data, setData] = useState([]);
    const [isExpend, setIsExpend] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentDate, setCurrentDate] = useState('');
    const [newsItems, setNewsItems] = useState([]);

    useEffect(() => {
        const today = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = today.toLocaleDateString('en-US', options);
        setCurrentDate(formattedDate);
    }, []);

    useEffect(() => {
        const fetchNews = async () => {
            setLoading(true);
            try {
                const res = await axios.get('https://www.worldesgdatalake.com/fetch_data/');
                setData(res.data);
            } catch (error) {
                console.log("Error fetching News:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
    }, []);

    const toggleExpand = () => {
        setIsExpend(!isExpend);
    };

    return (
        <>
            <div className="datalake-newsroom">
                <div className="esg-newsroom">
                    <div className='Bg-IMG'>
                        <img src={Backimg} alt="Background Vector" className="bg-image" />
                    </div>
                    <div className='news-text'>
                        <h1 className="esg-title">
                            ESG <span style={{ color: '#4caf50' }} className="newsroom-highlight">Newsroom</span>
                        </h1>
                        <p style={{ fontWeight: 'bold' }} className="esg-subtitle">
                            The latest ESG News, Articles and Blogs - updated as on {currentDate}.
                        </p>
                    </div>
                </div>
                <div className="esg-assets-container">
                    {loading && (
                        <div className="loader-container">
                            <Loader /> {/* Show loader while loading */}
                        </div>
                    )}
                    <Suspense fallback={<Loader />}>
                        {data.slice(0, isExpend ? data.length : 5).map((item, index) => (
                            <ESGAssetCard
                                key={index}
                                image={item.Image_URL}
                                heading={item.Title}
                                description={item.Description}
                                date={formatDate(item.Date)}
                                author={item.Source}
                                article={item.Link}
                            />
                        ))}
                    </Suspense>
                </div>
                <div className="view-all-container">
                    {!loading && data.length > 5 && (
                        <button className="view-all-button" onClick={toggleExpand}>
                            {isExpend ? 'Show Less ⟵' : 'Load More ⟶'}
                        </button>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default DataLakeNews;

