
import React from 'react';
import './ServiceCard.css';
import { Link } from 'react-router-dom';

const ServiceCard = ({ icon, title, description, buttonLabel, link }) => {
  return (
    <div className="service-card">
      <div className="service-icon">
        <img src={icon} alt={title} />
      </div>
      <div style={{textAlign : 'left'}}>
      <h3>{title}</h3>
      <p>{description}</p>
      <Link to={link}><button style={{cursor:'pointer'}} className="service-button">{buttonLabel}</button></Link>
      
      </div>
    </div>
  );
};

export default ServiceCard;