// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-section {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .services-section h2 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
  }
  
  .services-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
  }
  
.services-section {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .services-section h2 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
  }
  
  .services-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/ServiceSection/ServiceSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,cAAc;IACd,WAAW;EACb;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;EACX;;AAEF;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,cAAc;IACd,WAAW;EACb;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;EACX","sourcesContent":[".services-section {\n    padding: 20px;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .services-section h2 {\n    margin-bottom: 20px;\n    font-size: 2em;\n    color: #333;\n  }\n  \n  .services-cards {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 80px;\n  }\n  \n.services-section {\n    padding: 20px;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .services-section h2 {\n    margin-bottom: 20px;\n    font-size: 2em;\n    color: #333;\n  }\n  \n  .services-cards {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 80px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
