// src/pages/DataLake.js
import React from 'react';
const DataLake = () => {
    return (
        <div>Hello Brother</div>
    )
};

export default DataLake;

