import React from "react";
import { Link } from "react-router-dom";
import "./CSS/Consulting.css";
import Footer from "../Components/Footer/Footer";
import amico2 from "../Components/Images/amico1.png";
const ESG_Consulting = () => {
    return (
        <>
      <div className="esg-consulting-container">
        <div className="esg-consulting-header">
          <div className="esg-consulting-image">
            <img src={amico2} alt="ESG Consulting" />
          </div>
          <div className="esg-consulting-title">
            <h1>ESG Consulting</h1>
            <h2>Empowering Your Business with Strategic ESG Insights</h2>
            <p>
              In today's rapidly evolving business landscape, integrating ESG
              principles is more critical than ever. Our ESG Consulting service is
              designed to help you navigate this complexity with confidence and
              clarity.
            </p>
          </div>
        </div>
        <div className="couns">
        <div className="esg-consulting-content">
          <div className="esg-consulting-card">

            <p><span style={{color: 'rgba(84, 156, 108, 1)' ,fontWeight:'bold'}}>Customized Strategy Development:</span>
            We collaborate with you to craft tailored ESG strategies that align with your business objectives and values. Whether you’re starting your ESG journey or looking to enhance existing practices, our experts provide the insights and tools needed to embed sustainability into your core operations.
            </p>
          </div>
          <div className="esg-consulting-card">
          <p><span style={{color: 'rgba(84, 156, 108, 1)' ,fontWeight:'bold'}}>Risk and Opportunity Assessment: </span>
          We conduct thorough assessments to identify potential ESG risks and opportunities specific to your industry      and operational context. By understanding these factors, you can proactively address challenges and leverage opportunities for competitive advantage.            </p>
          </div>
          <div className="esg-consulting-card">
          <p><span style={{color: 'rgba(84, 156, 108, 1)' ,fontWeight:'bold'}}>Stakeholder Engagement: </span>
          Effective communication with stakeholders is key to successful ESG implementation. We help you develop engagement strategies that foster transparency, trust, and collaboration with investors, employees, customers, and the broader community.            </p>
          </div>
          <div className="esg-consulting-card">
          <p><span style={{color: 'rgba(84, 156, 108, 1)' ,fontWeight:'bold'}}>Performance Monitoring and Reporting: </span>
          Our team supports you in establishing robust ESG metrics and reporting frameworks.We ensure you can track progress, demonstrate accountability, and communicate your achievements to   stakeholders effectively</p>
          </div>
        </div>
        </div>
  
        <div className="esg-consulting-footer">
          <p>
            With our ESG Consulting, your organization will be well-equipped to
            lead with purpose and integrity in a world that increasingly values
            sustainability.
          </p>
          <Link to='/contact'><button className="cta-button">Book Consultation</button></Link>

        </div>
      </div>
      <Footer/>
      </>
    );
  };
  
  export default ESG_Consulting;