// src/pages/DataLakeNews.js
import React from 'react';
import { useState } from 'react';
import Backimg from "../Components/Images/image 4.png";
import "../App.css";
import Footer from '../Components/Footer/Footer';
const ESGFunds = () => {
    
    const data = [
        { fund: 'iShares ESG Aware MSCI USA ETF (NASDAQ:ESGU)', nav: '$119.29', returns: '28.97%' },
        { fund: 'SPDR S&P 500 ESG ETF (NYSEMKT:EFIV)', nav: '$53.25', returns: '27.36%' },
        { fund: 'Fidelity U.S. Sustainability Index Fund (NASDAQMUTFUND:FITL.X)', nav: '$24.62', returns: '27.14%' },
        { fund: 'Vanguard FTSE Social Index Fund Admiral Shares (NASDAQMUTFUND:VFTA.X)', nav: '$52.00', returns: '26.17%' },
        { fund: 'Nuveen ESG Mid-Cap Growth ETF (NYSEMKT:NUMG)', nav: '$41.81', returns: '17.01%' },
        { fund: 'Calvert International Responsible Index Fund (NASDAQMUTFUND:CDHI.X)', nav: '$30.52', returns: '17.80%' },
        { fund: 'Calvert International Responsible Index Fund (NASDAQMUTFUND:CDHI.X)', nav: '$32.60', returns: '16.70%' },
        { fund: 'iShares ESG Aware MSCI EM ETF (NASDAQ:ESGE)', nav: '$33.49', returns: '5.59%' },
        { fund: 'iShares ESG Aware 1-5 Year USD Corporate Bond ETF (NASDAQ:SUSB)', nav: '$24.47', returns: '4.88%' },
        { fund: 'Quant E.S.G Equity Fund', nav: '₹ 34.0861', returns: '50.22%' },
        { fund: 'Quantum India E.S.G Equity Fund', nav: '₹22.9600', returns: '27.48%' },
        { fund: 'Axis E.S.G. Equity Fund', nav: '₹ 21.96', returns: '29.59%' },
        { fund: 'Axis E.S.G. Equity Fund', nav: '₹ 17.724', returns: '29.69%' },
        { fund: 'ICICI Prudential E.S.G Fund', nav: '₹ 20.18', returns: '38.00%' },
        { fund: 'Invesco India E.S.G Equity Fund', nav: '₹ 16.96', returns: '32.29%' },
        { fund: 'Adity Birla Sun Life ESG Fund', nav: '₹ 16.67', returns: '30.03%' },
        { fund: 'SBI Magnum Equity E.S.G. Fund', nav: '₹ 235.0115', returns: '31.23%' },
        { fund: 'Kotak E.S.G. Opportunities Fund', nav: '₹ 16.83', returns: '33.94%' },
        { fund: 'HDFC Housing Opportunities', nav: '₹24.0270', returns: '62.67%' },
        { fund: 'Legal & General Global Technology Index Trust I Class Accumulation', nav: '₹ 174', returns: '47.02%' },
        { fund: 'Stewart Investors Indian Subcontinent Sustainability Fund Class B (Accumulation) GBP', nav: '535.49 GBX', returns: '26.30%' },
        { fund: 'FSSA Indian Subcontinent All-Cap Fund B GBP (Acc)', nav: '190.00 GBX', returns: '36.80%' },
        { fund: 'CT Global Focus Fund Z Gross Acc GBP', nav: '£2.4055', returns: '27.78%' },
        { fund: 'Aviva Investors Global Equity Endurance Fund 2 GBP Acc', nav: '193.8 GBX', returns: '16.38%' },
        { fund: 'Aviva Investors Global Equity Income Fund 2 GBP Acc', nav: '342.66 GBX', returns: '20.70%' },
        { fund: 'UBS ETF (LU) EURO STOXX 50 ESG UCITS ETF', nav: '17.6561 EUR', returns: '23.04%' },
        { fund: 'iShares Dow Jones Eurozone Sustainability Screened UCITS ETF (DE)', nav: 'EUR 18.04', returns: '25.51%' },
        { fund: 'L&G Quality Equity Dividends ESG Exclusions Europe ex-UK UCITS ETF', nav: '€11.74', returns: '25.41%' },
        { fund: 'iShares MSCI Europe SRI UCITS ETF', nav: 'EUR 70.57', returns: '17.36%' },
        { fund: 'iShares MSCI Europe ESG Enhanced UCITS ETF', nav: 'EUR 6.72', returns: '17.24%' },
        { fund: 'iShares MSCI Europe ESG Screened UCITS ETF', nav: 'EUR 8.39', returns: '18.93%' },
        { fund: 'iShares EURO STOXX 50 ESG UCITS ETF', nav: 'EUR 6.01', returns: '18.84%' },
        { fund: 'L&G Europe ex UK Equity UCITS ETF', nav: '€16.96', returns: '1.57%' },
        { fund: 'iShares MSCI Saudi Arabia ETF', nav: '$40.62', returns: '-12.60%' },
        { fund: 'iShares MSCI Thailand ETF', nav: '$54.26', returns: '53.70%' },
        { fund: 'iShares MSCI Turkey ETF', nav: '$42.02', returns: '14.93%' },
        { fund: 'iShares MSCI Israel ETF', nav: '$60.71', returns: '6.18%' },
        { fund: 'ARK Israel Innovative Technology ETF', nav: '$19.93', returns: '-0.12%' },
        { fund: 'BlueStar Israel Technology ETF', nav: '$45.52', returns: '-0.66%' },
        { fund: 'iShares MSCI Qatar ETF', nav: '$16.88', returns: '4.86%' },
        { fund: 'VanEck Israel ETF', nav: '$35.90', returns: '0.41%' },
        { fund: 'Franklin FTSE Saudi Arabia ETF', nav: '$34.68', returns: '25.38%' },
        { fund: 'Vanguard Total Stock Market ETF', nav: '$268.73', returns: '26.93%' },
        { fund: 'Vanguard S&P 500 ETF', nav: '$503.07', returns: '26.96%' },
        { fund: 'SPDR S&P 500 ETF Trust', nav: '$546.37', returns: '27.16%' },
        { fund: 'Fidelity 500 Index Fund', nav: '$190.95', returns: '27.13%' },
        { fund: 'iShares Core S&P 500 ETF', nav: '$549.32', returns: '11.56%' },
        { fund: 'Vanguard Total International Stock Index Fund', nav: '$60.14', returns: '32.94%' },
        { fund: 'Invesco QQQ Trust, Series 1', nav: '$480.37', returns: '36.13%' },
        { fund: 'Vanguard Growth Index Fund', nav: '$376.47', returns: '11.65%' },
        { fund: 'Vanguard Developed Markets Index Fund', nav: '$49.26', returns: '17.25%' },
        { fund: 'Vanguard Value Index Fund', nav: '$268.73', returns: '25.38%' },
        { fund: 'Vanguard Total Stock Market ETF', nav: '$503.07', returns: '26.93%' },
        { fund: 'Vanguard S&P 500 ETF', nav: '$546.37', returns: '26.96%' },
        { fund: 'SPDR S&P 500 ETF Trust', nav: '$190.95', returns: '27.16%' },
        { fund: 'Fidelity 500 Index Fund', nav: '$549.32', returns: '27.13%' },
        { fund: 'iShares Core S&P 500 ETF', nav: '$60.14', returns: '11.56%' },
        { fund: 'Vanguard Total International Stock Index Fund', nav: '$480.37', returns: '32.94%' },
        { fund: 'Invesco QQQ Trust, Series 1', nav: '$376.47', returns: '36.13%' },
        { fund: 'Vanguard Growth Index Fund', nav: '$49.26', returns: '11.65%' },
        { fund: 'Vanguard Developed Markets Index Fund', nav: '$161.15', returns: '17.25%' }
      ];
      

    const [isExpend, setisexpend] = useState(false);
    const itemsToShow = isExpend ? data.length : 15;


    const toggleviewall = () => {

        setisexpend(!isExpend);
    }

    return (
        <>
            <div className="esg-newsroom">
                <div className='Bg-IMG'>
                    <img src={Backimg} alt="Background Vector" className="bg-image" />
                </div>
                <div className='news-text policy'>
                    <h1 className="esg-title">
                        Top ESG <span style={{color: '#4caf50'}} className="newsroom-highlight">Funds</span>
                    </h1>
                    <p style={{fontWeight: 'bold'}} className="esg-subtitle">
                        The latest news about ESG Funds.
                    </p>
                </div>
            </div>
            <div style={{ margin: '20px 20px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#d9e9f4', borderRadius: '10px'  }}>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ccc',textAlign: 'left', paddingLeft: '35px'}}>Fund Name</th>
                            <th style={{ padding: '12px', borderBottom: '1px solid #ccc' }}>NAV</th>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>% 1 Year Returns</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(0, itemsToShow).map((item, index) => (
                            <tr key={index} style={{width:'80%' }}>
                                <td style={{ padding: '30px', borderBottom: '2px solid #ccc',textAlign: 'start' }}>{item.fund}</td>
                                <td style={{ padding: '8px', borderBottom: '2px solid #ccc', textAlign: 'center' }}>{item.nav}</td>
                                <td style={{ padding: '8px', borderBottom: '2px solid #ccc', textAlign: 'center' }}>{item.returns}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="view-all-container">
                <button className="view-all-button" onClick={toggleviewall}>
                    {isExpend ? 'Show Less ⟵' : 'View All ⟶'}
                </button>
            </div>
            <Footer />
        </>
    )
};

export default ESGFunds;
