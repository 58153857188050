// src/pages/Contact.js
import React from 'react';
import Footer from '../Components/Footer/Footer';
import HeroSection from '../Components/HeroSection/Hero';
import HowItWorksSection from '../Components/HowWorks/HowItWorkSection';
import InsightsSection from '../Components/InsightSection/Insight';
import NewsUpdates from '../Components/NewsUpdates/NewsUpdate';
import ServicesSection from '../Components/ServiceSection/ServiceSection';
import Esg_container from '../Components/Esg_Container/Esg_container';


const Home = () => {
    return <div>
        <HeroSection />
        <NewsUpdates />
        <Esg_container/>
        {/* <ServicesSection /> */}
        <InsightsSection />
        <HowItWorksSection />
        <Footer />
    </div>;
};

export default Home;
