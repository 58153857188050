import React from "react";
import "./CSS/Team.css";
import Caute from "./Images/cuate.png";
import Footer from "../Components/Footer/Footer";

const sampleImage = 'https://via.placeholder.com/150';

const teamData = {
  leadership: [
    { name: 'Nitin Kalra', role: 'Founder', img: sampleImage },
    { name: 'Aarushi Malhotra', role: 'Founder', img: sampleImage },
  ],
  advisory: [
    { name: 'Sesh Suk', role: 'Growth & Transformation Leader ', img: sampleImage },
    { name: 'George', role: 'ESG Expert', img: sampleImage },
    { name: 'Tamanna', role: 'ESG Expert ', img: sampleImage },
  ],
  development: [
    { name: 'Chirag Thakur', role: 'Tech Lead', img: sampleImage },
    { name: 'Nirmit', role: 'AI Expert', img: sampleImage },
    { name: 'Imraan', role: 'Data Scientist', img: sampleImage },
    { name: 'Yogesh', role: 'Data Analyst', img: sampleImage },
    { name: 'Priyanshi', role: 'Backend Lead', img: sampleImage },
    { name: 'Harpreet', role: 'Backend Developer', img: sampleImage },
    { name: 'Rohit', role: 'Front-end Lead', img: sampleImage },
    { name: 'Krish', role: 'Front-end Developer', img: sampleImage },
    { name: 'Rishu', role: 'Front-end Developer', img: sampleImage },
    { name: 'Divyanshi', role: 'Design Expert', img: sampleImage },
  ],
};

const TeamSection = ({ title, team, gridClass = 'first second third' }) => (
    <div className="team-section">
    <h2>{title}</h2>
    <div className={`team-grid ${gridClass}`}>
      {team.map((member, index) => (
        <div className="team-member" key={index}>
          <img src={member.img} alt={member.name} className="team-img" />
          <h3 className="team-name">{member.name}</h3>
          <p className="team-role">{member.role}</p>
        </div>
      ))}
    </div>
  </div>
  );
  
  const Team = () => {
    return (
        <div>
      <div className="team-container">
        <div className="upper-header"></div>
        <div className="team-header">
          <h1 style={{fontSize:'60px'}}>Our Team</h1>
          <img src={Caute} alt="Team" className="header-image" />
        </div>
        <div className="whole-team">
            <div className="New-outer">
        <TeamSection title="Leadership Team" team={teamData.leadership }gridClass ="grid-2x2" />
        </div>
        <div className="New-outer">
        <TeamSection title="Advisory Team" team={teamData.advisory} />
        </div>
        {/* Add the 'grid-3x3' class for the Development Team */}
        <div className="New-outer">
        <TeamSection title="Development Team" team={teamData.development} gridClass="grid-3x3" />
        </div>
        </div>
      </div>
      <Footer/>
      </div>
    );
  };
  
export default Team;