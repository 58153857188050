import React from "react";
import "./Esg_container.css"; // Assuming you have some CSS for styling
import report from "../Images/A4 - 1 1.png";
import chart from "../Images/Group 391.png";
import Booking from "../Images/rafiki.png";
import Draft from "../Images/Draft.png";
import { Link } from "react-router-dom";
import check from "../Images/checkmark.png";
import Pen from "../Images/Group 409.png";
import machine from "../Images/Group 408.png"

// const handleDownload = () => {
//   const link = document.createElement("a");
//   link.href = "/ESG assessment analysis report.pdf"; // Update this pat.h to your actual PDF file
//   link.download = "ESG_Report.pdf"; // This will be the name of the downloaded file
//   link.click();
// };

const Esg_container = () => {
  const steps = [
    {
      id: '01',
      title: 'Select Your Reporting Framework',
      description: [
        'Choose from global ESG frameworks like CSRD, SEC, GRI, SASB, TCFD, or create custom reports for sustainability, investor relations, or customer communication.',
        'Access expert guidance from top ESG consultants and best practices to ensure your reporting is accurate, comprehensive, and compliant.',
      ],
    },
    {
      id: '02',
      title: 'Centralize Your Data with Generative AI',
      description: [
        'Effortlessly upload documents and audios, including PDFs, scans, handwritten notes, spreadsheets, recorded calls; let Generative AI do the data reading.',
        'Integrate existing systems like ERP / any other applications for seamless data import and invite unlimited collaborators to refine and centralize data.',
      ],
    },
    {
      id: '03',
      title: 'Enrich, Transform, and Harmonize Data',
      description: [
        'Leverage AI to standardize, enrich, and clean your data, with automatic anomaly detection for quick validation.',
        'Fill in gaps by notifying teams and use AI-powered recommendations to complete missing information.',
        'Confidently calculate GHG emissions (carbon accounting) using proven methods and reliable sources like Ecoinvent, EPA, and IEA.',
      ],
    },
    {
      id: '04',
      title: 'Seamless Audits',
      description: [
       'Prepare your ESG metrics for SEC, CSRD, and other mandatory or voluntary reports with ease.',
        'Track your ESG progress through dynamic dashboards, analyzing performance in real-time at every level—from individual projects to global operations.',
      ],
    },
    {
      id: '05',
      title: 'Real-Time Visualizations and Dashboards',
      description: [
        'Prepare your ESG metrics for SEC, CSRD, and other mandatory or voluntary reports with ease.',
        'Track your ESG progress through dynamic dashboards, analyzing performance in real-time at every level—from individual projects to global operations.',
      ],
    },
  ];

  // Data for the Offerings section
  const offerings = [
    {
      title: 'Compliance Consulting',
      points: [
        'Tailored reporting frameworks based on your industry, country, and specific requirements.',
        'Enhanced collaboration with built-in messaging and task management tools.',
        'Cross-framework data population for streamlined reporting.',
        'Direct auditor access for seamless verification and assurance.',
      ],
    },
    {
      title: 'Generative AI Solutions',
      points: [
        'Automated data extraction from various sources, including PDFs, Word documents, Excel files, scanned copies, and handwritten notes.',
        'AI-driven data cleaning, enrichment, standardization, and centralization.',
        'Advanced generative AI models to capture summaries from recorded calls and videos.',
        'Multilingual support for translations and surveys in all global languages.',
        'Integration with 250+ systems, ensuring a smooth data flow.',
        'Dedicated supplier portals and site-specific management tools.',
      ],
    },
    {
      title: 'Expert Consulting Services',
      points: [
        'Comprehensive double ESG materiality assessments to identify key impact areas.',
        'Gap assessments to uncover and address reporting deficiencies.',
        'Science-based target setting for sustainable business growth.',
        'In-depth life cycle assessments for a holistic view of your environmental impact.',
        'CDP submission support to enhance your environmental disclosures.',
        'Strategies to improve your MSCI rating and ESG assurance readiness.',
      ],
    },
  ];
  return (
    <div className="esg-overview-container">
    <div className="Container1">
      <img src={Pen} alt=""/>
        <h1>Five Steps to ESG Success </h1>
        <img src={machine} alt=""/>
      </div>
      {/* Steps Section */}
      <div className="steps-container">
        {steps.map((step, index) => (
          <div className="step-item" key={index}>
            <div className="step-number">{step.id}</div>
            <div className="step-content">
              <h3 className="step-title">{step.title}</h3>
              <ul className="step-description">
                {step.description.map((desc, idx) => (
                  <li key={idx}>{desc}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
       {/* Image Container */}
       <div className="image-container">
        <img src={Booking} alt="ESG Illustration" className="centered-image" />
      </div>

      {/* Offerings Section */}
      <div className="offerings-container">
        <h2 className="offerings-title">Our ESG Offerings</h2>
        <div className="offerings-cards">

          {offerings.map((offering, index) => (
            <div className="offering-card" key={index}>
              <h3 className="offering-title">{offering.title}</h3>
              <ul className="offering-points">
                {offering.points.map((point, idx) => (
                  <li key={idx} className="offering-point">
                  <img src={check} alt="Checkmark" className="checkmark-icon" />
                  <span>{point}</span>
                </li>               
              ))}
              </ul>
              <Link to='/contact'><button className="cta-button">Book Now</button></Link>

            </div>
            
          ))}

        </div>
        
      </div>
    </div>
  );
};

export default Esg_container;
