

// src/components/Navbar.js
import React, { useState,useEffect} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import "./Header.css";
import carbon from "../Images/news-paper_svgrepo.com.png";
import News from "../Images/news.png";
import Funds from "../Images/funds.png";
import Govern from "../Images/government_svgrepo.com.png";
import group from "../Images/Group.png";
import logo from "../Images/esg logo final.png"

const Header = () => {
    const location = useLocation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isDropdownActive, setIsDropdownActive] = useState(false);

    useEffect(() => {
        // Check if the current path matches any of the dropdown links
        const dropdownPaths = [
            '/data-lake/news',
            '/data-lake/policy',
            '/data-lake/carbon',
            '/data-lake/circular',
            '/data-lake/funds',
            '/data-lake/investment'
        ];
        setIsDropdownActive(dropdownPaths.includes(location.pathname));
    }, [location]);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const dropdown =()=>{
         setDropdownOpen(false)
    }

    return (
        <nav className="navbar">
            <div className="navbar-logo"><NavLink to="/"><img src={logo} alt='ESG WORLD'/></NavLink></div>
            <ul className="navbar-links">
                <li className='btn'><NavLink to="/">Home</NavLink></li>
                <li 
                     className={`dropdown ${isDropdownActive ? 'active-parent' : ''}`}
                    onMouseEnter={toggleDropdown}
                    onMouseLeave={dropdown}
                >
                    <div className="dropbtn">
                        Data Lake <span className="arrow-down">﹀</span>
                    </div>
                    {dropdownOpen && (
    
                        <div className="dropdown-content">
    
                            <div className="dropdown-column">
                                
                                <NavLink className="drop  d1" to="/data-lake/news"> <img src = {News} alt='Carbon market'/>ESG News, Articles, and Blogs</NavLink>
                                <NavLink className="drop" to="/data-lake/policy"><img src = {Govern} alt='Carbon market'/> ESG Government Policy and Regulations</NavLink>
                                <NavLink className="drop" to="/data-lake/carbon"><img src = {carbon} alt='Carbon market'/> Carbon market and Green Economy Policy measures</NavLink>
                            </div>
                            <div className="dropdown-column drop-right">
                                <NavLink className="drop d2" to="/data-lake/circular"> <img src = {group} alt='Carbon market'/> Circular Economy Index</NavLink>
                                <NavLink className="drop d3" to="/data-lake/funds"> <img src = {Funds} alt='Carbon market'/> ESG Funds</NavLink>
    
                            </div>
            
                        </div>
                    )}
                </li>
                <li className='btn'><NavLink to="/teams">Teams</NavLink></li>

                <li className='btn'><NavLink to="/about">About Us</NavLink></li>
                <li className='btn'><NavLink to="/contact">Contact Us</NavLink></li>
            </ul>
        </nav>
    );
};

export default Header;
