// src/pages/Contact.js
import React from 'react';
import { FlagIcon } from 'react-flag-kit';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Backimg from "../Components/Images/image 4.png";
import "../App.css";
import Footer from '../Components/Footer/Footer';
const Circular = () => {
    const data = [
        { country: 'Norway', rank: 1, score: 71.81, code: 'NO' },
        { country: 'United Kingdom', rank: 2, score: 69.55, code: 'GB' },
        { country: 'Netherlands', rank: 3, score: 65.84, code: 'NL' },
        { country: 'Canada', rank: 4, score: 65.51, code: 'CA' },
        { country: 'Germany', rank: 5, score: 65.46, code: 'DE' },
        { country: 'Switzerland', rank: 6, score: 64.42, code: 'CH' },
        { country: 'United States', rank: 7, score: 62.69, code: 'US' },
        { country: 'Spain', rank: 8, score: 61.23, code: 'ES' },
        { country: 'Australia', rank: 9, score: 59, code: 'AU' },
        { country: 'France', rank: 10, score: 57.22, code: 'FR' },
        { country: 'Republic of Korea', rank: 11, score: 56.57, code: 'KR' },
        { country: 'Japan', rank: 12, score: 53.4, code: 'JP' },
        { country: 'Chile', rank: 13, score: 48.46, code: 'CL' },
        { country: 'Italy', rank: 14, score: 48.25, code: 'IT' },
        { country: 'Singapore', rank: 15, score: 47.8, code: 'SG' },
        { country: 'United Arab Emirates', rank: 16, score: 47.38, code: 'AE' },
        { country: 'China', rank: 17, score: 42.38, code: 'CN' },
        { country: 'Brazil', rank: 18, score: 42.27, code: 'BR' },
        { country: 'Costa Rica', rank: 19, score: 41.45, code: 'CR' },
        { country: 'Saudi Arabia', rank: 20, score: 41.33, code: 'SA' },
        { country: 'Qatar', rank: 21, score: 40.39, code: 'QA' },
        { country: 'Viet Nam', rank: 22, score: 39.96, code: 'VN' },
        { country: 'Colombia', rank: 23, score: 39.37, code: 'CO' },
        { country: 'Poland', rank: 24, score: 38.54, code: 'PL' },
        { country: 'Malaysia', rank: 25, score: 38.28, code: 'MY' },
        { country: 'Egypt', rank: 26, score: 38.16, code: 'EG' },
        { country: 'South Africa', rank: 27, score: 38.06, code: 'ZA' },
        { country: 'Mexico', rank: 28, score: 37.72, code: 'MX' },
        { country: 'Indonesia', rank: 29, score: 37.44, code: 'ID' },
        { country: 'Türkiye', rank: 30, score: 36.83, code: 'TR' },
        { country: 'Kazakhstan', rank: 31, score: 36.76, code: 'KZ' },
        { country: 'Thailand', rank: 32, score: 35.73, code: 'TH' },
        { country: 'Dominican Republic', rank: 33, score: 35.52, code: 'DO' },
        { country: 'Russian Federation', rank: 34, score: 35.45, code: 'RU' },
        { country: 'Peru', rank: 35, score: 35.41, code: 'PE' },
        { country: 'Jordan', rank: 36, score: 34.92, code: 'JO' },
        { country: 'Bahrain', rank: 37, score: 34.91, code: 'BH' },
        { country: 'Argentina', rank: 38, score: 34.69, code: 'AR' },
        { country: 'Ecuador', rank: 39, score: 34.52, code: 'EC' },
        { country: 'Oman', rank: 40, score: 34.36, code: 'OM' },
        { country: 'Morocco', rank: 41, score: 34, code: 'MA' },
        { country: 'Philippines', rank: 42, score: 33.63, code: 'PH' },
        { country: 'Ghana', rank: 43, score: 32.09, code: 'GH' },
        { country: 'Azerbaijan', rank: 44, score: 30.38, code: 'AZ' },
        { country: 'Tunisia', rank: 45, score: 30.08, code: 'TN' },
        { country: 'Angola', rank: 46, score: 30.04, code: 'AO' },
        { country: 'India', rank: 47, score: 30.01, code: 'IN' },
        { country: 'Sri Lanka', rank: 48, score: 28.29, code: 'LK' },
        { country: 'Kuwait', rank: 49, score: 27.71, code: 'KW' },
        { country: 'Lebanon', rank: 50, score: 26.89, code: 'LB' },
        { country: 'Côte D\'Ivoire', rank: 51, score: 26.31, code: 'CI' },
        { country: 'Iraq', rank: 52, score: 26.18, code: 'IQ' },
        { country: 'Guatemala', rank: 53, score: 25.94, code: 'GT' },
        { country: 'Uzbekistan', rank: 54, score: 25.79, code: 'UZ' },
        { country: 'Iran', rank: 55, score: 24.34, code: 'IR' },
        { country: 'Algeria', rank: 56, score: 24.13, code: 'DZ' },
        { country: 'Pakistan', rank: 57, score: 23.88, code: 'PK' },
        { country: 'Kenya', rank: 58, score: 23.48, code: 'KE' },
        { country: 'Bangladesh', rank: 59, score: 22.95, code: 'BD' },
        { country: 'Cameroon', rank: 60, score: 20.84, code: 'CM' },
        { country: 'Nigeria', rank: 61, score: 20.24, code: 'NG' },
        { country: 'Tanzania', rank: 62, score: 18.24, code: 'TZ' },
        { country: 'Uganda', rank: 63, score: 17.76, code: 'UG' },
        { country: 'Ethiopia', rank: 64, score: 17.18, code: 'ET' }
    ];


    const [isExpend, setisexpend] = useState(false);
    const itemsToShow = isExpend ? data.length : 15;
    const source = 'https://cceindex.kapsarc.org/cceindex/lab/tool2';

    const toggleviewall = () => {

        setisexpend(!isExpend);
    }

    return (
        <>
            <div className="esg-newsroom">
                <div className='Bg-IMG'>
                    <img src={Backimg} alt="Background Vector" className="bg-image" />
                </div>
                <div className='news-text policy'>
                    <h1 className="esg-title">
                        Circular Economy Index <span style={{ color: '#4caf50' }} className="newsroom-highlight">Analysis</span>
                    </h1>
                    <p style={{fontWeight: 'bold'}} className="esg-subtitle">
                        Analyze country-specific circular economy rankings and index scores.
                    </p>
                </div>
            </div>
            <div style={{display:'flex', background: 'none', paddingRight: '50px',justifyContent: 'end' }}><Link  to={source}> <button style={{border: 'none', backgroundColor: 'white', borderBottom: '2px solid black', color: 'black',fontSize: '12px', cursor:'pointer', marginTop: '10px'}}>Source: CCE INDEX</button></Link>
            </div>
            <div style={{ margin: '10px 20px' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr style={{ backgroundColor: '#d9e9f4', border: '2px' }}>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ccc', textAlign: 'left' }}>Country</th>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ccc' }}>Rank</th>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ccc', textAlign: 'center' }}>Circular Carbon Economy Index score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(0, itemsToShow).map((item, index) => (
                            <tr key={index}>
                                <td style={{ padding: '30px', borderBottom: '1px solid #ccc', textAlign: 'left' }}><div style={{ display: 'flex' }}><FlagIcon code={item.code} style={{ marginRight: 10 }} width="30" alt={`${item.country} flag`} />
                                    {` ${item.country}`}</div></td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ccc', textAlign: 'center' }}>{item.rank}</td>
                                <td style={{ padding: '8px', borderBottom: '1px solid #ccc', textAlign: 'center' }}><div>{item.score}</div></td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="view-all-container">
                <button className="view-all-button" onClick={toggleviewall}>
                    {isExpend ? 'Show Less ⟵' : 'View All ⟶'}
                </button>
            </div>
            <Footer />
        </>
    )
};

export default Circular;
