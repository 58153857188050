import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NewsCard from '../Cards/NewsCard/NewsCard';
import './NewsUpdate.css';

const NewsUpdates = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      try {
        const res = await axios.get('https://www.worldesgdatalake.com/fetch_data/');
        const fetchedNews = res.data.slice(0, 3); // Get only the top three news items
        setNewsItems(fetchedNews);
        setActiveIndex(Math.floor(fetchedNews.length / 2));
      } catch (error) {
        console.log("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const handleLeftClick = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? newsItems.length - 1 : prevIndex - 1));
  };

  const handleRightClick = () => {
    setActiveIndex((prevIndex) => (prevIndex === newsItems.length - 1 ? 0 : prevIndex + 1));
  };

  if (loading) {
    return (
      <div className="loading-spinner">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <section className="news-updates">
      <h2>Real Time News & Updates</h2>
      <button className="slider-button left" onClick={handleLeftClick}>
        &larr;
      </button>
      <div className="news-list">
        {newsItems.map((item, index) => {
          let className = 'news-card';
          if (index === activeIndex) {
            className += ' active';
          } else if (
            index === (activeIndex + 1) % newsItems.length ||
            index === (activeIndex - 1 + newsItems.length) % newsItems.length
          ) {
            className += ' neighbor';
          }
          return (
            <NewsCard
              key={index}
              className={className}
              Image_URL={item.Image_URL}
              Title={item.Title}
              Description={item.Description}
              Date={formatDate(item.Date)} // Format the date here
            />
          );
        })}
      </div>
      <button className="slider-button right" onClick={handleRightClick}>
        &rarr;
      </button>
    </section>
  );
};

export default NewsUpdates;
