// HowItWorksSection.js
import React from 'react';
import Line from "../Images/Line.png"
import './HowItWorksSection.css';
import image2 from "../Images/cuate.png"
import { Link } from 'react-router-dom';
const steps = [
  {
    id: 1,
    title: 'Book consultation',
    description: 'Schedule a convenient time for an initial consultation to discuss your ESG requirements and goals.',
  },
  {
    id: 2,
    title: 'Fill Details',
    description: ' Provide us with some basic information about your organization and its current ESG practices.',
  },
  {
    id: 3,
    title: 'We will contact you through E-mail',
    description: 'Our team will review your information and get back to you via email with tailored insights and next steps.',
  },
];

const HowItWorksSection = () => {
  return (
    <>
    <div className="how-it-works-section">
      <h2>How It Works</h2>
      <img style={{width: '67%'}} src={Line} alt='line' />
      <div className="steps">
        {steps.map((step) => (
          <div key={step.id} className="step">
            <div className="step-number">{step.id}</div>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </div>
    <div className="why-choose-us-section">
    <h2>Why Choose Us?</h2>
    <p>Choose us for our unparalleled expertise and commitment to driving sustainable excellence through cutting-edge ESG solutions.</p>
    <Link to="/about"><button className="see-more-button">See More &rarr;</button></Link>
    
    <div className="image-container">
      <img src={image2} alt="Why Choose Us" />
    </div>
  </div>
  </>
  );
};

export default HowItWorksSection;
