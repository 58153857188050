
import React from 'react';
import "./insight.css";
import backgroundVector from "../Images/Vector 1.png";
import worldMap from "../Images/image 4.png";
import img from "../Images/trending-up_svgrepo.com.png"
import { Link } from 'react-router-dom';

const insights = [
    { id: 1, icon: img,title: 'ESG Government Policy and Regulations', category: 'Explore Data', link:'/data-lake/policy' },
    { id: 2, icon: img, title: 'Circular Economy Index', category: 'Explore Data', link: '/data-lake/circular' },
    { id: 3, icon: img, title: 'ESG Funds', category: 'Explore Data',link:'/data-lake/funds' },
];

const InsightsSection = () => {
    return (
        <div className="insights-section">
            <div className="background-container">
                <img src={backgroundVector} alt="Background Vector" className="background-image" />
            </div>
            <div className="content-container">
                <div className='Insight'>
                <div className="insight-header">
                    <h2>Insights</h2>
                    <p>Discover the world's first ESG Data Lake, delivering unparalleled global insights into environmental, social, and governance metrics.</p>
                </div>
                <ul className="insight-list">
                    {insights.map((insight) => (
            
                        <li key={insight.id}>
                            <div className='insight-div'><img src={insight.icon} alt="Icon" className="insight-icon" />
                            <h3>{insight.title}</h3>
                            </div>
                            <Link to={insight.link}><button style={{background: 'none', border: '1px solid white', padding : '7px 17px', borderRadius: '20px', color: 'white',fontSize: '10px', cursor:'pointer'
                            }}>{insight.category}</button></Link>
                            
                        </li>
                        
                    ))}
                </ul>
                </div>
                
                <div className="world-map">
                    <img src={worldMap} alt="World Map" />
                </div>
            </div>
        </div>
    );
};

export default InsightsSection;
