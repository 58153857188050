import React from 'react';
import Backimg from "../Components/Images/image 4.png";
import "../App.css";
import Footer from '../Components/Footer/Footer';
const ESGInvestment = () => {
    return (
        <>
            <div className="esg-newsroom">
                <div className='Bg-IMG'>
                    <img src={Backimg} alt="Background Vector" className="bg-image" />
                </div>
                <div className='news-text policy'>
                    <h1 className="esg-title">
                        ESG Government Policies
                        and <span style={{color: '#4caf50'}} className="newsroom-highlight">Regulation</span>
                    </h1>
                    <p style={{fontWeight: 'bold'}} className="esg-subtitle">
                        The latest government policies and regulations related to ESG.
                    </p>
                </div>
            </div>
            <Footer/>
            </>
    )
};

export default ESGInvestment;