import React, { useState } from 'react';
import Backimg from "../Components/Images/image 4.png";
import "../App.css";
import Pdf from "./Images/image 5.png";
import Footer from '../Components/Footer/Footer';
import dataSheets from '../Components/DataSheets/policy';

const ReportCard = ({ Title, Description, Link }) => {
    const fileId = extractFileId(Link); // Extract file ID from Google Drive link
    const downloadLink = `https://drive.google.com/uc?id=${fileId}&export=download`;

    function extractFileId(link) {
        const regex = /\/d\/([A-Za-z0-9_-]+)\//;
        const match = link.match(regex);
        return match ? match[1] : ''; // Returns the file ID
    }

    return (
        <div style={styles.card}>
            <h3>{Title}</h3>
            <p>{Description}</p>
            <a href={downloadLink} style={styles.button} download>
                <img src={Pdf} alt="PDF Icon" width="15" height="15" style={styles.image} />
                {"Click to download PDF"}
            </a>
        </div>
    );
};


const ESGPolicy = () => {
    const [isExpend, setisexpend] = useState(false);
    const itemsToShow = isExpend ? dataSheets.length : 5;


    const toggleviewall = () => {
        
        setisexpend(!isExpend);
    }

    return (
        <>
            <div>
                <div className="esg-newsroom">
                    <div className='Bg-IMG'>
                        <img src={Backimg} alt="Background Vector" className="bg-image" />
                    </div>
                    <div className='news-text policy'>
                        <h1 className="esg-title">
                            ESG Government Policies
                            and <span style={{color: '#4caf50'}} className="newsroom-highlight">Regulation</span>
                        </h1>
                        <p style={{fontWeight:'bold'}} className="esg-subtitle">
                        Access insights on the latest global ESG government policies and regulations.
                        </p>
                    </div>
                </div>
                <div style={{padding: "20px"}}>
                <div style={styles.container}>
                    {dataSheets.slice(0, itemsToShow).map((report, index) => (
                        <ReportCard key={index} {...report} />
                    ))}
                    
                </div>
                </div>
                <div className="view-all-container">
                    <button className="view-all-button" onClick={toggleviewall}>
                        {isExpend ? 'Show Less ⟵' : 'View All ⟶'}
                    </button>
                </div>
                    <Footer />
            </div>
           
        </>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        marginTop: '20px',
    },
    card: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '20px',
        width: '80%',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        textAlign: 'left',
    },
    button: {
        display: 'inline-block',
        marginTop: '10px',
        padding: '10px 20px',
        backgroundColor: 'rgba(55, 54, 86, 1)',
        color: '#fff',
        textDecoration: 'none',
        borderRadius: '22px',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    image: {
        paddingRight: '10px', // Add margin to create space between the icon and text
    }
};

export default ESGPolicy;
