import React from "react";
import "./CSS/Certificate.css";
import Footer from "../Components/Footer/Footer";
import amico from "../Components/Images/amico.png";
import { Link } from "react-router-dom";
const ESG_Certificate = () => {
    return (
        <>
            <div className="esg-certification">
                <section className="intro">
                    <div>
                        <img src={amico} alt=" esg-Certificate" />
                    </div>
                    <div className=" words">
                        <h1>ESG Certification</h1>
                        <h2>Showcasing Your Commitment to Sustainability</h2>
                        <p>
                            Earning ESG Certification is a powerful way to demonstrate your dedication to sustainable and responsible business practices. Our certification process is rigorous and comprehensive, designed to provide clear validation of your ESG efforts.
                        </p>
                    </div>

                </section>
                <div className="card">
                <section className="benefits">

                    <div className="benefit-card item1">
                    <p><span style={{color: 'rgba(84, 156, 108, 1)',fontWeight:'bold'}}>Rigorous Evaluation Process:  </span>
                    We assess your organization’s ESG performance against a set of robust criteria, covering environmental stewardship, social responsibility, and governance practices. This thorough evaluation ensures that only those who are truly committed to excellence are certified.                   </p>
                    </div>
                    <div className="benefit-card item2">
                    <p><span style={{color: 'rgba(84, 156, 108, 1)',fontWeight:'bold'}}>Transparent and Credible Standards: </span>
                    Our certification is based on transparent, globally recognized standards. This credibility enhances your reputation and builds trust among stakeholders, from investors to customers.                    </p>
                    </div>
                    <div className="benefit-card item3">
                    <p><span style={{color: 'rgba(84, 156, 108, 1)',fontWeight:'bold'}}>Continuous Support and Improvement: </span>
                    Certification is not a one-time achievement but a commitment to ongoing excellence. We provide continuous support to help you maintain and improve your ESG performance over time.                    </p>
                    </div>
                    <div className="benefit-card item4">
                    <p><span style={{color: 'rgba(84, 156, 108, 1)',fontWeight:'bold'}}>Competitive Advantage: </span>
                    With ESG Certification, you can differentiate your organization in the marketplace. It signals to partners and consumers that you are a leader in sustainability, opening doors to new opportunities and growth.                     </p></div>
                    <div className="benefit-card item5">
                    <p><span style={{color: 'rgba(84, 156, 108, 1)',fontWeight:'bold'}}>Enhanced Stakeholder Confidence:</span>
                    Certified organizations often enjoy increased confidence and loyalty from stakeholders who value responsible business practices. This can lead to stronger relationships and long-term success.                    </p>
                    </div>

                </section>
            </div>
            <section className="cta">
                <p>
                    Pursuing ESG Certification with us is not just about meeting standards; it’s about embracing a culture of sustainability that drives positive change and propels your business forward.
                </p>
                <Link to='/contact'><button className="cta-button">Book Consultation</button></Link>
                
            </section>

        </div >
            <Footer />
        </>
    )
};
export default ESG_Certificate;