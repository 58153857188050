import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div style={{display: 'flex', justifyContent: 'left', width: '60%'}}>
      <div className="footer-column">
        <h3>Contact us</h3>
        <p>nk@worldesgdatalake.com</p>
        <p>am@worldesgdatalake.com</p>
        <p>Two Horizon Center, Golf Course Road, DLF Phase 5, Sector 43, Gurgaon, Haryana 122002, India</p>
        <div className="social-icons">
          <a href="#"><i className="fab fa-facebook-f"></i></a>
          <a href="#"><i className="fab fa-linkedin-in"></i></a>
          <a href="#"><i className="fab fa-twitter"></i></a>
          <a href="#"><i className="fab fa-instagram"></i></a>
        </div>
      </div>
      <div className="footer-column"style={{marginLeft:'50px'}}>
        <h3>Products</h3>
        <ul>
          <li ><Link className="no-decoration" to= "/ESG-certificate">ESG Certification</Link></li>
          <li><Link className="no-decoration" to= "/ESG-consulting">ESG Consulting</Link></li>
          <li><Link className="no-decoration" to= "/ESG-Gap">ESG Gap Analysis</Link></li>
          
        </ul>
      </div>
      </div>

      <div className="footer-bottom">
        <div className="language-select">
          <a href="#">English</a>
        </div>
        <p>Copyright © 2024. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
