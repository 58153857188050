import React from "react";
import { Link } from "react-router-dom";
import "./CSS/Gap.css";
import Footer from "../Components/Footer/Footer";
import amico3 from "../Components/Images/amico2.png";

const ESg_Gap = () => {
    return (
        <>
            <div className="esg-container">
                <div className="esg-header">
                    <div className="esg-header-image">
                        <img src={amico3} alt="ESG Certificate" />
                    </div>
                    <div className="esg-header-text">
                        <h1>ESG Gap Analysis</h1>
                        <h2>Bridging the Gap to ESG Excellence</h2>
                        <p>
                            Understanding where you stand on your ESG journey is the first step towards meaningful improvement. Our ESG Gap Analysis service provides a deep dive into your current practices, pinpointing areas that require attention and offering actionable solutions.
                        </p>
                    </div>
                </div>

                <div className="esg-content">
                    <div className="esg-card">
                        
                        <p>
                        <span style={{color: 'rgba(84, 156, 108, 1)',fontWeight:'bold'}}>Comprehensive Evaluations: </span>
                        We conduct detailed evaluations of your ESG policies, processes, and performance across all relevant domains. This holistic approach ensures that no aspect of your operations is overlooked.
                         </p>
                    </div>
                    <div className="esg-card">
                    <p>
                        <span style={{color: 'rgba(84, 156, 108, 1)', fontWeight:'bold'}}>Benchmarking Against Best Practices: </span>
                        By comparing your ESG performance to industry standards and best practices, we identify gaps and highlight areas where you can elevate your approach to meet or exceed expectations.                         </p>
                    </div>
                    <div className="esg-card">
                    <p>
                        <span style={{color: 'rgba(84, 156, 108, 1)',fontWeight:'bold'}}>Actionable Recommendations: </span>
                        Our findings are translated into clear, practical recommendations that guide you on how to bridge identified gaps. We provide a roadmap for improving compliance, efficiency, and impact.                         </p>
                    </div>
                    <div className="esg-card">
                    <p>
                        <span style={{color: 'rgba(84, 156, 108, 1)',fontWeight:'bold'}}>Prioritization of Initiatives: </span>
                        Not all gaps are equal. We help you prioritize initiatives based on their potential impact and feasibility, ensuring your resources are focused on the most critical areas for improvement.                         </p>
                    </div>
                    <div className="esg-card">
                    <p>
                        <span style={{color: 'rgba(84, 156, 108, 1)',fontWeight:'bold'}}>Continuous Improvement Plans: </span>
                        ESG is a dynamic field, and staying ahead requires ongoing effort. We assist you in developing plans for continuous improvement, keeping your organization on the path to long-term sustainability.                         </p>
                    </div>
                </div>

                <div className="esg-footer">
                    <p>
                        Our ESG Gap Analysis equips you with the knowledge and direction needed to enhance your ESG performance and build a stronger, more resilient organization.
                    </p>
                    <Link to='/contact'><button className="cta-button">Book Consultation</button></Link>

                </div>
            </div>
            <Footer />
        </>
    );
};

export default ESg_Gap;
