import React from 'react';
import { BrowserRouter as Router, Route, Routes,Switch} from 'react-router-dom';
import Header from './Components/Header/Header';
import Home from './Pages/Homepage';
import About from './Pages/about';
import Contact from './Pages/Contact';
import DataLake from './Pages/DataLake';
import ESGFunds from './Pages/ESGFunds';
import ESGInvestment from './Pages/ESGInvestment';
import ESGPolicy from './Pages/ESGPolicy';
import DataLakeNews from './Pages/ESGNews';
import CarbonMarket from './Pages/CarbonMarket';
import Circular from './Pages/Circular';
import ESg_Gap from './Pages/ESGGap';
import ESG_Consulting from './Pages/Esg-consulting';
import ESG_Certificate from './Pages/Esg-certificate';
import ScrollToTopOnPageChange from './Components/ScrollTop/Scroll';
import Team from './Pages/Team';

const App = () => {
        
        return (
                <Router>
                        <Header />
                        <ScrollToTopOnPageChange/>
                        <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/data-lake" element={<DataLake />} />
                                <Route path="/data-lake/funds" element={<ESGFunds />} />
                                <Route path="/data-lake/investment" element={<ESGInvestment />} />
                                <Route path="/data-lake/policy" element={<ESGPolicy />} />
                                <Route path="/data-lake/news" element={<DataLakeNews />} />
                                <Route path="/data-lake/carbon" element={<CarbonMarket />} />
                                <Route path="/data-lake/circular" element={<Circular />} />
                                <Route path='teams' element= {<Team/>}/>
                                <Route path="/ESG-Gap" element={<ESg_Gap />} />
                                <Route path="/ESG-certificate" element={<ESG_Certificate />} />
                                <Route path="/ESG-consulting" element={<ESG_Consulting />} />

                        </Routes>
                </Router>
        );
}

export default App;
