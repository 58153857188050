import React from 'react';
import ServiceCard from '../Cards/ServiceCard/ServiceCard';
import "./ServiceSection.css";
import Consulting from "../Images/image-removebg-preview (16).png";
import Gap from "../Images/image-removebg-preview (17).png";
import Certification from "../Images/Screenshot_2024-06-20_162941-removebg-preview.png";

const services = [
  {
    id: 1,
    icon: Consulting,
    title: 'ESG Consulting',
    description: 'Tailored strategies and insights to help organizations integrate environmental, social, and governance principles into their business operations.',
    buttonLabel: 'Know More',
    link: '/ESG-consulting'
  },
  {
    id: 2,
    icon: Certification,
    title: 'ESG Gap Analysis',
    description: 'Comprehensive assessments to identify gaps in ESG performance and develop actionable plans for improvement.',
    buttonLabel: 'Know More',
    link:'/ESG-Gap'
  },
  {
    id: 3,
    icon: Gap,
    title: ' ESG Certification',
    description: 'Rigorous certification processes that validate and recognize an organizations commitment to sustainable and responsible practices.',
    buttonLabel: 'Know More',
    link:'/ESG-certificate'
  },
];

const ServicesSection = () => {
  return (
    <div className="services-section">
      <h2>Our Services</h2>
      <div style={{display: ''}}>
      <div className="services-cards">
        {services.map((service) => (
          <ServiceCard
            key={service.id}
            icon={service.icon}
            title={service.title}
            description={service.description}
            buttonLabel={service.buttonLabel}
            link = {service.link}
          />
        ))}
      </div>
      </div>
    </div>
  );
};

export default ServicesSection;
