
const Sheet=[
    {
        "Title": "A healthy environment and a healthy economy go hand in hand",
        "Description": "Canadas strengthened climate plan to create jobs and support people communities and the planet, Unless otherwise specified you may not reproduce materials in this publication in whole or in part for the purposes of commercial redistribution without prior written permission. To obtain permission to reproduce Government of Canada materials for commercial purposes apply for Crown Copyright Clearance by contacting Environment and Climate Change Canada.",
        "Link": "https://drive.google.com/open?id=1gZy-S5B_hbJc19WY54tsdXFmSs_AnuvB&usp=drive_copy"
    },
    {
        "Title": "A Measurement Strategy for Green Economy in Korea",
        "Description": "In Korea a measurement strategy for Green Economy has been implemented through three approaches indicator sets environmental accounts and green industry statistics. Green Growth Indicators GGIs and Green Lifestyle indicators GLIs have been compiled as a statistical indicator system for crosscheck and evaluation of green growth policies. SEEA has been developed for monitoring environmental policies and concrete activities of Green Growth strategy.",
        "Link": "https://drive.google.com/open?id=1S-snA1cPe6V73feKK4jyY2Xphtnr2t_x&usp=drive_copy"
    },
    {
        "Title": "Advancing Carbon Markets An Overview of German Initiatives",
        "Description": "Climate change is a pressing global challenge requiring significant economic and lifestyle changes. International carbon markets, initiated by the Kyoto Protocol with mechanisms like the Clean Development Mechanism (CDM) and Joint Implementation (JI), are key to efficient resource allocation for these changes. Efforts continue to enhance these mechanisms and develop new market approaches, supported by initiatives like the Partnership for Market Readiness (PMR) and the World Bank's Carbon Initiative for Development (Ci-Dev), with the Federal Ministry for the Environment supporting innovative carbon market activities.",
        "Link": "https://drive.google.com/open?id=10JCJr1rXEAHoVxgET_cWVlEBkliRmlZz&usp=drive_copy"
    },
    {
        "Title": "America's New Climate Economy A comprehensive guide to the economic benefits of climate policy in the United States",
        "Description": "The U.S. has been reducing emissions while growing its economy, with a 25% increase in GDP and a 12% decrease in CO2 emissions from 2005 to 2018. This progress, driven by renewable energy, a shift from coal to gas, and improved vehicle standards, is led by states and local governments. However, federal policy rollbacks, such as those under the Trump administration, threaten long-term climate goals and economic savings.",
        "Link": "https://drive.google.com/open?id=1d78fa2hMRQWsZ-nxvMSBe55oTkJ8_UZs&usp=drive_copy"
    },
    {
        "Title": "Australia-Singapore Green Economy Agreement",
        "Description": "The Green Economy Agreement GEA has brought the Government of the Commonwealth of Australia Australia and Singapore together to accelerate both countries transition towards a green and sustainable future. The GEA will support economic growth create jobs in green sectors promote the decarbonisation of economic activities and mainstream sustainability taking into account our climate policies and plans.",
        "Link": "https://drive.google.com/open?id=19sQuOZNMNgtB5xX2jSVJWco1wzrxHJkO&usp=drive_copy"
    },
    {
        "Title": "Australia's Green Economic Potential",
        "Description": "The Centre for Policy Development (CPD) is a research institute focused on integrating innovative and sustainable ideas into Australian policy debates. Highlighting the urgent need to address the costs of short-term thinking in public policy, CPD's Sustainable Economy Research Program seeks to realign economic activities within environmental limits and promote long-term sustainability. This briefing paper, inspired by the UN's Green Economy Report but independently produced, discusses the challenges and opportunities of adopting sustainable economic policies in Australia, particularly in water, energy, urban transport, and waste management.",
        "Link": "https://drive.google.com/open?id=1Mqoj3IySKRVBL_6ZwxGmvcDB9xg7wV5O&usp=drive_copy"
    },
    {
        "Title": "Barcelona Declaration Spanish Green Growth Group",
        "Description": "The Spanish Green Growth Group comprises a group of companies in Spain. They aim to convey their vision of an economic growth model that is compatible with the efficient use of natural resources. Efficient use means working together to protect biodiversity the quality of air soil and water and of course reduce greenhouse gas emissions linked to climate change.",
        "Link": "https://drive.google.com/open?id=1BdC06N8k5-gQfk5XypcXjXZ2VBshzxUA&usp=drive_copy"
    },
    {
        "Title": "Building a clean energy economy",
        "Description": "The Inflation Reduction Act makes a historic commitment to build a new clean energy economy powered by American innovators American workers and American manufacturers. It will create goodpaying union jobs and cut the pollution that is fueling the climate crisis and driving environmental injustice. The Act will deliver results through a combination of grants loans rebates incentives and other investments to support the Presidents wholeofgovernment economic plan.",
        "Link": "https://drive.google.com/open?id=1GqRk3vFZ5NGCm2vDF9w4QGBTVYA4awJC&usp=drive_copy"
    },
    {
        "Title": "Canada and the green economy",
        "Description": "Canada has a complex relationship with the global efforts to move to a green economy. Its policymakers and business leaders need to balance the countrys vast natural resources and the economic growth that they can foster. Report explores what the green economy means to Canada with a particular focus on Canadian companies and the accountancy profession.",
        "Link": "https://drive.google.com/open?id=1JaaHbtxdtrXvisVxKT1OHrdLO-T0vbVy&usp=drive_copy"
    },
    {
        "Title": "Canada and green economy: The Role for Philanthropy",
        "Description": "Canada's future economy will be a green economy, promising significant environmental and economic benefits. Achieving this requires transforming our economic activities to preserve natural systems, with solutions emerging from governments, businesses, and civil society. This document offers foundational insights into the green economy, highlighting its importance, barriers, opportunities, and the role of Canada's philanthropic community in driving this transformation.",
        "Link": "https://drive.google.com/open?id=1Tk9hXaVZ_Musx5jsjZGU-JdjsdEguZsO&usp=drive_copy"
    },
    {
        "Title": "Canada climate actions healthy environment healthy economy",
        "Description": "Canada is committed to reducing its emissions by 40 to 45 percent below 2005 levels by 2030 and to achieving netzero by 2050. Canada and Canadians stand to gain both from the environmental benefits of climate action to a country thats warming at twice the global average and from the economic opportunity to mobilize Canadas skilled workers.",
        "Link": "https://drive.google.com/open?id=1reigGA8lEIe5KRk0kBNm3gFLmPuTIMCf&usp=drive_copy"
    },
    {
        "Title": "Carbon markets in Gulf Cooperation Council (GCC) countries",
        "Description": "KAPSARC is an advisory think tank within global energy economics and sustainability providing advisory services to entities and authorities in the Saudi energy sector. In December 2022 the King Abdullah Petroleum Studies and Research Center KAPSARC organized a workshop on carbon markets in Gulf Cooperation Council GCC countries. The event brought together more than 30 carbon market stakeholders from the GCC to discuss past experiences.",
        "Link": "https://drive.google.com/open?id=1mjvYzWEuZ0WmmScJ6jpZImJQVLo76yCI&usp=drive_copy"
    },
    {
        "Title": "Carbon offsetting",
        "Description": "A carbon credit represents the reduction removal or prevented release of greenhouse gases by natural or technological processes. Businesses and individuals can purchase credits on the voluntary carbon market and may use them to offset their own emissions. Ensuring that credits truly represent carbon reduction or removal is complex and uncertain. Government consultation will consider supporting the growth of highintegrity carbon and nature markets.",
        "Link": "https://drive.google.com/open?id=1L5CqvSGRkcfVhSIZsNpBq4ldE0S4XgDF&usp=drive_copy"
    },
    {
        "Title": "Carbon Plan For Britain",
        "Description": "Carbon Plan sets out a vision of a changed Britain powered by cleaner energy used more efficiently in our homes and businesses. It shows exactly how we will deliver that vision and play our part in the global effort to tackle climate change. We want the public to tell us where we can be even more ambitious and hold us to account.",
        "Link": "https://drive.google.com/open?id=1d6RefJ2SfJXLZfRGPvB-P83SdmHSsNZI&usp=drive_copy"
    },
    {
        "Title": "Green Growth Strategy Through Achieving Carbon Neutrality in 2050",
        "Description": "In October 2020, Japan committed to achieving carbon neutrality by 2050, with a target to reduce greenhouse gas emissions by 46% from 2013 levels by 2030. The Green Growth Strategy aims to transform the industrial structure and promote economic growth through environmental sustainability. The government will support private sector innovation and set high goals, especially focusing on decarbonizing the energy sector, which accounts for over 80% of emissions.",
        "Link": "https://drive.google.com/open?id=1mF587Dk5-4xuz10eO_cLHgRLKl50T0jQ&usp=drive_copy"
    },
    {
        "Title": "China's climate policies include a focus on developing carbon trading markets",
        "Description": "China has emerged as an important actor on the global stage with regards to the United Nations UN climate negotiations. China played a vital role in the successful entryintoforce of the Paris Agreement PA and has continued to show commitment to its implementation. While the carbon and energy intensity targets for 2020 outlined in the 13th Five Year Plan FYP appear to be within reach the recent increase in coal consumption in China has led to concerns.",
        "Link": "https://drive.google.com/open?id=1jB1_ffevf4GjGEOnx8r92_fGD6UuVgte&usp=drive_copy"
    },
    {
        "Title": "Green Economy Report and Action Plan",
        "Description": "Industrialized countries current lifestyles are unsustainable due to over-exploitation of natural resources. The green economy aims to enhance resource efficiency and economic performance while safeguarding environmental sustainability. Switzerland's Federal Council, recognizing this need, mandated actions in 2010 to transition towards a green economy, focusing on improving resource efficiency and mitigating long-term environmental impacts.",
        "Link": "https://drive.google.com/open?id=17LemaBPGOkIJFp6j0DwISS2yZDTEe21u&usp=drive_copy"
    },
    {
        "Title": "China's policy strategies aim for green, low-carbon development",
        "Description": "This work is available through open access by complying with the Creative Commons licence created for intergovernmental organizations. The findings interpretations and conclusions expressed herein are those of the authors and do not necessarily reflect the views of the United Nations or its officials or Member States. The rapid industrialization and urbanization have resulted in resources and environmental challenges for the country which calls for action.",
        "Link": "https://drive.google.com/open?id=1dMO0r6w1FKkHdjeiAbrI3RxIpv4sBwxg&usp=drive_copy"
    },
    {
        "Title": "Japan's GX Policy and Carbon Pricing Trends",
        "Description": "In Japan the declaration of carbon neutrality in 2050 by then Prime Minister Suga in October 2020 triggered a flurry of discussions on carbon pricing. In 2022 policies for realizing the Green Transformation GX of the industry structure toward carbon neutrality were discussed under the title of the GX Implementation Conference. In February 2023 the Cabinet approved the Basic Policy for Achieving GX and the Overall Roadmap for the Next 10 Years. The GX Promotion Bill was enacted in May 2023.",
        "Link": "https://drive.google.com/open?id=13oCJFZnRvragaDnDIqM-L6F689Wiu8dd&usp=drive_copy"
    },
    {
        "Title": "China's path to a green economy",
        "Description": "China's transition to a green economy has significant global implications for sustainable development and climate change. This report traces the evolution of China's green economy policies, highlights key macro and sectoral policies, and examines stakeholders and unique characteristics. It also identifies areas for further research to improve policy implementation and global environmental impact reduction",
        "Link": "https://drive.google.com/open?id=14hAkVNtHJ04buZQQ465TKXwxUGVN3WRt&usp=drive_copy"
    },
    {
        "Title": "Climte change strategy in the Emirate of Abu Dhabi",
        "Description": "The Climate Change Strategy in the Emirate of Abu Dhabi 2023-2027 reflects the emirates ambition for effective climate action at various levels. The strategy promotes greater resilience in key sectors and makes adaptation to climate change integral to government entities plans. It outlines Abu Dhabis plan to reduce greenhouse gases GHGs significantly in line with the countrys announcement of achieving climate neutrality by 2050. Likewise the strategy drives innovation in carbon capture and storage and identifies actions aimed at economic diversification.",
        "Link": "https://drive.google.com/open?id=16XlgMyp26zSm2I2C3QbcJttKXoh-8kuM&usp=drive_copy"
    },
    {
        "Title": "CMI Westpac Carbon Market Report",
        "Description": "Carbon Market Institute is a memberbased institute accelerating the transition towards a negative emissions nature positive world. The 2024 CMIWestpac Carbon Market Report Carbon Markets Australias Net Zero Challenge forms part of the CMIs ongoing research initiative. The report is the first in a new annual series that examines how Australias carbon market is evolving.",
        "Link": "https://drive.google.com/open?id=1D4u3PDdg4GX4JQ_mCTcbTck5RqIDpGwJ&usp=drive_copy"
    },
    {
        "Title": "Enabling the transition to a Green Economy",
        "Description": "The legacy of the last decade was an economy built on unsustainable levels of public and private debt and too heavy a reliance on financial services. We need strong sustainable and balanced growth that is more evenly shared across the country and between industries. A key element of this plan is continuing the transition to a green economy. There are strong economic arguments for moving now to avoid burdening our future generations with the costs of early inaction.",
        "Link": "https://drive.google.com/open?id=1pE7zv20lcMnX4kx_1UWWdvikuOVbIBWe&usp=drive_copy"
    },
    {
        "Title": "Exploring the market for voluntary carbon offsets",
        "Description": "The Clean Development Mechanism (CDM), part of the Kyoto Protocol, has faced criticism for favoring large, high-volume projects over small, community-based initiatives due to high costs and bureaucratic hurdles. In contrast, the voluntary carbon offset market allows more flexibility, supporting smaller projects that offer co-benefits like local economic development and biodiversity conservation. This market is driven by companies, governments, and individuals seeking to offset carbon emissions outside of regulatory frameworks.",
        "Link": "https://drive.google.com/open?id=1S6Bzdt1eD_tCXtz53KsiNCsY150RU3Mt&usp=drive_copy"
    },
    {
        "Title": "GCC Program Manual",
        "Description": "The Global Carbon Council (GCC) is the MENA region's inaugural voluntary carbon offsetting program, led by the Gulf Organization for Research and Development (GORD). It aims to foster a sustainable, low-carbon economy by supporting climate actions aligned with UN Sustainable Development Goals. GCC addresses shortcomings of previous GHG programs by emphasizing regional participation, transparency, and simplified project registration to reduce costs and enhance effectiveness in mitigating climate change.",
        "Link": "https://drive.google.com/open?id=1T0DOMCFzTue0tqwa13qa4m7IXd5FGQu5&usp=drive_copy"
    },
    {
        "Title": "Germany's international cooperation on carbon markets",
        "Description": "Germany plays a pivotal role in advancing global carbon markets through initiatives like PMR and CPLC, focusing on ETS development and linking trading schemes internationally. Post-Paris, Germany seeks to align its cooperation efforts to further develop Article 6 mechanisms, supporting partner countries at varying stages of carbon market readiness. Case studies highlight strategic pathways for Germany to enhance rule-based market instruments and inform international negotiations.",
        "Link": "https://drive.google.com/open?id=1dFKRpvr69vyjvHmza5Z0rd3ad_KxbYz2&usp=drive_copy"
    },
    {
        "Title": "Germany's current climate action status",
        "Description": "Germany aims for greenhouse gas neutrality by 2045, aligning with EU and G7 goals. Urgent action is crucial to mitigate climate impacts and maintain global competitiveness. The Federal Government is launching the Immediate Climate Action Programme to achieve these targets by 2022.",
        "Link": "https://drive.google.com/open?id=1S5J73mFfqpetGz3DF2_Il5G1dMgNRsgF&usp=drive_copy"
    },
    {
        "Title": "Green Growth and Green Economy in Japan",
        "Description": "The strategy focuses on greening public investments and infrastructure through eco-building renovations, sustainable transportation systems, forestry for carbon mitigation, and promoting energy-efficient technologies alongside carbon pricing and offsetting measures.",
        "Link": "https://drive.google.com/open?id=1_Ygx-_dYX42HwsRuVzYj07Dv8pc4UZEy&usp=drive_copy"
    },
    {
        "Title": "Green Growth Strategy (Overview)",
        "Description": "Japan is setting ambitious targets for renewable energy: 10GW by 2030 and 30-45GW by 2040, focusing on developing power grids and port infrastructure. Initiatives include establishing a Power Grid Master-plan by FY2022 and studying a long-distance HVDC transmission system. They aim to achieve competitive supply chains with 60% local content by 2040 and cost-effective offshore wind power at 8-9 yen/kWh by 2030-2035, supported by regulatory reforms in electricity and maritime safety laws.",
        "Link": "https://drive.google.com/open?id=1_Ygx-_dYX42HwsRuVzYj07Dv8pc4UZEy&usp=drive_copy"
    },
    {
        "Title": "House of Commons Environmental Audit Committee A Green Economy",
        "Description": "The UK's approach to transitioning to a green economy lacks strategic vision and clear targets, focusing too much on voluntary actions and market-led solutions. There's a critical need for comprehensive policies that integrate social, economic, and environmental goals, setting time-bound milestones for emissions reduction and sustainable practices. To lead effectively on the global stage, the UK must prioritize green investment, enhance policy certainty, and ensure all government departments are aligned towards sustainable development.",
        "Link": "https://drive.google.com/open?id=1U5TfE3pG9xcIvRxhCf77e7w29l_5X1Bk&usp=drive_copy"
    },
    {
        "Title": "Japan's policies regarding the green economy and Sustainable Development Goals (SDGs)",
        "Description": "Japan's approach to green economy and SDGs lacks integration and clear focus, with existing policies mainly oriented towards economic growth and societal issues rather than environmental sustainability. The concept of SDGs is perceived as primarily concerning international assistance rather than domestic policy priorities. Japan's recent economic strategies since 2012 emphasize innovation, socio-economic issues, and energy policies that prioritize nuclear and efficient fossil fuels over renewable energy and environmental concerns.",
        "Link": "https://drive.google.com/open?id=1M9gbEouW2s-RGFVdnznZU0rIeiS09T_X&usp=drive_copy"
    },
    {
        "Title": "Japan's green transformation GX",
        "Description": "Green Transformation GX refers to the transformation of the entire economic and social system from an economy society and industrial structure dependent on fossil fuels to structures driven by clean energy. The aim of the initiative is to drive economic growth and development through emissions mitigation. Five key initiatives being discussed by the government to achieve 150 trillion JPY approx US1 trillion of privatepublic investment to bring about green transformation GX.",
        "Link": "https://drive.google.com/open?id=1WhDhjNcdrgAltJ74hB73aom8YtuNY72u&usp=drive_copy"
    },
    {
        "Title": "Joint policy statement and principles on voluntary carbon markets",
        "Description": "Leaders of US Federal departments and offices issue statement and associated principles on VCMs. Statement provides observations on the current state of VCMs and their potential. It then outlines voluntary principles that US market participants should embrace as they engage in these markets. These principles will also guide how the US Government engages with VCMs While the focus of this statement is the focus is While VCMs remain relatively small today they have the potential to grow in the coming years.",
        "Link": "https://drive.google.com/open?id=1_zogb9S4jAX8NZbhDTEIpQxVxbjUNT5_&usp=drive_copy"
    },
    {
        "Title": "Just Transition Strategy",
        "Description": "Spain's Strategic Framework for Energy and Climate, approved in February 2019, outlines ambitious targets and strategies for decarbonizing its economy. This includes achieving 100% renewable electricity and net zero emissions across all sectors by 2050 under the draft bill on Climate Change. The National Integrated Energy and Climate Plan (PNIEC) aims for a 23% reduction in greenhouse gas emissions by 2030, surpassing current EU targets, alongside significant increases in renewable energy and energy efficiency.",
        "Link": "https://drive.google.com/open?id=1798g50huzOEn92FR-zCuxvF0OYXykbSL&usp=drive_copy"
    },
    {
        "Title": "Landscape, opportunities, and challenges for private sector engagement in Nepal",
        "Description": "Climate change is urgent, driven by human activities increasing greenhouse gases. The UNFCCC aims to stabilize these gases, emphasizing immediate reductions to limit warming to 1.5\u00b0C. This report focuses on Voluntary Carbon Markets (VCM) as flexible tools for private and public sectors to offset emissions, complementing regulatory frameworks like the CDM. VCMs enhance climate ambition, mobilize finance, and support Sustainable Development Goals (SDGs) through innovative emissions reduction initiatives.",
        "Link": "https://drive.google.com/open?id=1kK4X_kmqr02m5SmNgrmJTYWPJYwYK5dc&usp=drive_copy"
    },
    {
        "Title": "LSE green economy mark report 2023",
        "Description": "The global shift towards net zero emissions has accelerated, covering over 90% of global GDP. The green economy, growing at 14% annually, now rivals the fossil fuel sector with a $7 trillion market capitalization. Investment in clean energy is soaring, projected to reach $1.7 trillion by 2023, driven by robust regulatory support like the EU Green Deal. However, substantial annual investments of $4\u20136 trillion are needed to achieve global climate goals, highlighting the scale of the challenge ahead.",
        "Link": "https://drive.google.com/open?id=1LbrIdttAMuPzPX4BEcxKKqLnzgpitjF2&usp=drive_copy"
    },
    {
        "Title": "Making the UK a global leader in carbon markets",
        "Description": "The UK is well positioned to become a leading centre for carbon markets bolstered by its green policies and strategies already implemented and planned for the future. The report concluded that carbon markets could play a significant role in reaching net zero targets but that greater scale is needed to make this contribution meaningful. Policy recommendations drawn from this report outline strategic steps for the UK government to capitalise on this opportunity.",
        "Link": "https://drive.google.com/open?id=1iyRsL9YR0fghaYiOlbY8yyJrgA_zKPRJ&usp=drive_copy"
    },
    {
        "Title": "National carbon market strategy CMI policy brief",
        "Description": "CMI policy brief June 2024 Harnessing carbon markets to accelerate to Net Zero. In 2022 the Climate Change Authority recommended that the Australian Government develop and publish a National Carbon Market Strategy. Carbon markets are a vehicle for boosting ambition and accelerating decarbonisation. They do so by allowing for the cooperative purchase and transfer of greenhouse gas mitigation outcomes.",
        "Link": "https://drive.google.com/open?id=1icE_ng0wcrVklov-KRSFu6mXcJihV7_s&usp=drive_copy"
    },
    {
        "Title": "National Green Growth Strategy of South Korea",
        "Description": "Korea's 'Low Carbon, Green Growth' vision, launched in 2008, includes a voluntary 30% reduction in GHG emissions by 2020 and the implementation of a national Emission Trading Scheme (ETS) starting in 2015. This strategy has spurred significant investments in green technologies and shifted public attitudes towards climate change, demonstrating early signs of success through consistent political commitment and coordinated efforts across ministries and private sectors.",
        "Link": "https://drive.google.com/open?id=15tTrndXj238cbBMmhkh2E5s9QVWzkv-g&usp=drive_copy"
    },
    {
        "Title": "National Low Carbon Strategy",
        "Description": "The National Low Carbon Strategy (SNBC) for France, introduced in March 2020, outlines a comprehensive roadmap aimed at achieving carbon neutrality by 2050. This strategy is a pivotal component of French climate policy, legally binding for the public sector. It sets ambitious goals for reducing greenhouse gas emissions and emphasizes the involvement of public decision-makers at various levels. The SNBC mandates the integration of climate objectives into planning and programming documents, sectoral policies, and regional plans. It requires rigorous monitoring and revision every five years, supported by indicators to assess progress. The strategy emphasizes collaboration with stakeholders and public participation in its development, ensuring alignment with international climate agreements and national environmental codes.",
        "Link": "https://drive.google.com/open?id=1yeoi2c47iN3Fk4JWaEmrv78mn00PjD4Z&usp=drive_copy"
    },
    {
        "Title": "National strategies for carbon markets under the Paris Agreement",
        "Description": "Asia and the Pacific, responsible for over 50% of global greenhouse gas emissions, are highly vulnerable to climate change impacts. Urgent action and substantial financing are needed to secure a sustainable future. Well-designed carbon markets are essential for reducing emissions, promoting low-carbon technologies, and generating climate adaptation funds. The Asian Development Bank (ADB) supports these efforts through its Carbon Market Program, helping countries utilize carbon markets to meet their climate goals. This publication provides a framework for policymakers to effectively integrate carbon markets into their climate strategies, aligning with ADB's broader climate action initiatives.",
        "Link": "https://drive.google.com/open?id=1nR8IGsVlbOSVRSX_oynpk_Ml2l2jcaha&usp=drive_copy"
    },
    {
        "Title": "NYCEDC Green Economy Action Plan",
        "Description": "The Green Economy Action Plan builds on New York Citys spirit of resilience and the work underway across city government to offer an integrated economic development agenda and talent strategy. The action plan contains six new contributions to our understanding of New York's green economy and a roadmap to reducing carbon emissions and creating a more climate conscious city.",
        "Link": "https://drive.google.com/open?id=1Cx-FBU2Ok2KA4iIcUJN_YTpr9sfVGIP6&usp=drive_copy"
    },
    {
        "Title": "Revolutionizing the voluntary carbon market",
        "Description": "The UAE is rapidly advancing its sustainability agenda, aiming for net zero emissions by 2050. Key initiatives include declaring 2023 as the 'Year of Sustainability' and plans to develop local carbon trading platforms. Abu Dhabi Global Market (ADGM) and UAE's Security and Commodities Authority (SCA) are leading efforts with ambitions to launch regulated carbon trading exchanges, enhancing the region's role in global green finance.",
        "Link": "https://drive.google.com/open?id=1kOwL52Bm2yNujM8STruxY4TDSa9wkx8f&usp=drive_copy"
    },
    {
        "Title": "Singapore-Australia Green Economy Agreement advancing cooperation on voluntary carbon markets",
        "Description": "An opportunity exists for Australia and Singapore to work together to develop this new multi billion Carbon Removal industry. This will enable the countries and corporations of the South East Asian region to meet their net zero by 2050 commitments. The Submission from Southern Green Gas Ltd (SGG) and Corporate Carbon Group Pty Ltd (CCG) collaboration involves deploying eventually millions of solar powered DAC units in regional Australia to pull CO2 from the atmosphere.",
        "Link": "https://drive.google.com/open?id=19pq9BxQz3KSAVR0th2IDZgeHsnny-ChQ&usp=drive_copy"
    },
    {
        "Title": "Switzerland The World's Carbon Markets A Case Study Guide to Emissions Trading",
        "Description": "The foundation of Swiss environmental policy was established in 1985. In 1999 Switzerland adopted the Act on the Reduction of CO2 Emissions CO2 Act as a supplementary environmental policy that centers on carbon dioxide CO2 mitigation. The goals and mechanisms outlined in these two Acts were designed to help Switzerland meet its Kyoto Protocol commitment of 8 greenhouse gas GHG reduction relative to 1990.",
        "Link": "https://drive.google.com/open?id=1FcwKQ0-vJsnYDgj2hZIxSiIOua1AROCP&usp=drive_copy"
    },
    {
        "Title": "The Carbon Pollution Reduction Scheme Green Paper",
        "Description": "NSW has a greenhouse gas emissions reduction target of a 60 reduction on 2000 levels by 2050. The CPRS should be designed to attain the challenging greenhouse targets in coming decades as efficiently as possible minimising economic risks and costs. Delaying emissions reductions will increase the aggregate costs of achieving greenhouse emission.",
        "Link": "https://drive.google.com/open?id=1v6_9a_qDxzxRMlIZ6PdFte8zBYZTAke-&usp=drive_copy"
    },
    {
        "Title": "The climate foreign policy strategy of the Government of the Federal Republic of Germany",
        "Description": "The climate crisis is changing our world in fundamental ways. Swift ambitious and cooperative action by the international community is vital if the Paris climate goals are to be delivered. Only together will we be able to achieve the necessary worldwide reduction of greenhouse gas emissions. No country is in a position to protect itself from the impacts of climate change or curb the crisis on its own.",
        "Link": "https://drive.google.com/open?id=1RfYUbUkzhG20q_NUlpnNXnUsJlAgmEgt&usp=drive_copy"
    },
    {
        "Title": "The development of climate change policy in Germany",
        "Description": "Germany is the leader among countries belonging to the OECD in reducing its emissions of the Kyoto basket of greenhouse gases GHGs. Germany accomplished an emissions reduction of 189 percent from 1990 to 2000. This paper aims to explain the reasons for this success. It concludes that domestic factors including the participation of the Greens in the coalition government mainly determined Germanys success in developing advanced climate policies.",
        "Link": "https://drive.google.com/open?id=1jge67ly9UyO1L9gHNmIc5aCFbDKFLK39&usp=drive_copy"
    },
    {
        "Title": "The Evolution of Canada's carbon markets",
        "Description": "Carbon credits are one of the key tools that will allow conventional businesses to continueoperating as the economy decarbonizes. They can also facilitate investment in newtechnologies and practices that will be critical to achieving material economywideemissions reductions. There are key barriersthat are limiting carbon markets and that highlight the need for more carbon financeinvestment and policy certainty.",
        "Link": "https://drive.google.com/open?id=1yadleS4a04eKmEY6m4ZmzQWDHgJnryrn&usp=drive_copy"
    },
    {
        "Title": "The potential role of carbon labeling in a green economy",
        "Description": "Over the past several years labeling schemes that focus on a wide range of environmental and social metricshave proliferated. Although little empirical evidence has been generated yet with respect to carbon footprintlabels much can be learned from our experience with similar product labels. We argue that it is important to considerthe entire life cycle of a product being labeled and develop an international standard for measurement andreporting. Finally we examine the potential impact of carbon product labeling discussing methodologicaland trade challenges.",
        "Link": "https://drive.google.com/open?id=13LQ3zWvFLsewBE_3BC1lxtvGezvGceMP&usp=drive_copy"
    },
    {
        "Title": "The rise of carbon taxation in France",
        "Description": "A carbon tax is a pivotal policy tool aimed at internalizing environmental costs by pricing carbon emissions, influencing consumer and business decisions toward low-carbon alternatives. While generating revenue, its primary objective is mitigating climate change by reshaping economic behaviors across sectors. This approach underscores the transformative role of carbon pricing in steering global economies towards sustainability.",
        "Link": "https://drive.google.com/open?id=1grQSwcGCagvZEmQFNMDUTH87J9tv93t4&usp=drive_copy"
    },
    {
        "Title": "The UAE General Environmental Policy 2021",
        "Description": "The United Arab Emirates General Environmental Policy 2021. The policy aims to enhance the quality of life in the UAE now and in the future. It focuses on preserving the countrys biodiversity and the sustainability of its resources ecosystems and services. It also aims to increase the contribution of the livestock and agricultural sectors to the national economy and food diversification.",
        "Link": "https://drive.google.com/open?id=1PAunq5OczD1pQaWXHxWnYLlCNE5eU45N&usp=drive_copy"
    },
    {
        "Title": "Transforming Canada's economy for a global low-carbon future",
        "Description": "Canada faces a critical juncture in its economic trajectory as global climate policies rapidly reshape markets. Balancing proactive climate policies with industry competitiveness will be pivotal for long-term prosperity. Adaptation to new market realities is crucial for Canada's economic sectors, despite facing challenges such as emissions intensity and shifting investor expectations.",
        "Link": "https://drive.google.com/open?id=1BduPyYtoC20MwtXaW_YscZgYg1TS8oXS&usp=drive_copy"
    },
    {
        "Title": "UAE-Abu Dhabi global market ESG",
        "Description": "The Covid19 pandemic has undoubtedly accelerated the adoption and integration of ESG practices in both business and government. ADGM is proud to be at the forefront of the sustainable finance industry and remains committed to collaborating with its partners. The UAE became the first GCC country to announce a netzero carbon commitment the Net Zero by 2050 Strategic Initiative.",
        "Link": "https://drive.google.com/open?id=1UbZKR_AZnLgn1Q2tGOfoAP8E4Hlp2CLX&usp=drive_copy"
    },
    {
        "Title": "UNEP Policy Makers on Green Economy",
        "Description": "The challenge of the 21st century is to sustainably support a growing global population while achieving the Millennium Development Goals (MDGs), despite potential delays. The Green Economy Initiative aims to integrate economic growth with environmental sustainability, crucial for reducing poverty, improving health, empowering women, and fostering global partnerships in trade and finance. Policymakers must prioritize investment and regulation to drive this transition towards a more equitable and environmentally resilient future.",
        "Link": "https://drive.google.com/open?id=1zEGxMjSbgDiswy4Z01Nd7WvvE9Jb14PS&usp=drive_copy"
    },
    {
        "Title": "Green economic recovery the France experience",
        "Description": "The seminar cycle 'Green Economic Recovery' aims to inspire Brazil with international strategies for transitioning to carbon-neutral economies. France's agenda includes significant policies addressing emissions within the European context, highlighting its role in global climate action and development strategies towards carbon neutrality.",
        "Link": "https://drive.google.com/open?id=1pKKyRaxIJ9RtEncmNrlk3Dg4XKTM6xRc&usp=drive_copy"
    }
]

export default Sheet;