// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 1050px) {
    .contact{
      min-height: 900px;
      
    }
  }
@media only screen and (max-width: 1050px) {
    .contact{
      min-height: 900px;
      
    }
  }`, "",{"version":3,"sources":["webpack://./src/Pages/CSS/Contact.css"],"names":[],"mappings":"AAAA;IACI;MACE,iBAAiB;;IAEnB;EACF;AACF;IACI;MACE,iBAAiB;;IAEnB;EACF","sourcesContent":["@media only screen and (max-width: 1050px) {\n    .contact{\n      min-height: 900px;\n      \n    }\n  }\n@media only screen and (max-width: 1050px) {\n    .contact{\n      min-height: 900px;\n      \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
