

import React from 'react';
import './loader.css'; // Make sure to put your CSS in this file

const Loader = () => (
  <div className="loader">
    <div className="inner one"></div>
    <div className="inner two"></div>
    <div className="inner three"></div>
  </div>
);

export default Loader;
