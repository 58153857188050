
import React, { useState } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import ReactCountryFlag from 'react-country-flag';
import Footer from '../Components/Footer/Footer';
import "./CSS/Contact.css"

const Contact = () => {

  const [country, setCountry] = useState(null);
  const options = countryList().getData().map(country => ({
    value: country.value,
    label: <><ReactCountryFlag countryCode={country.value} svg style={{ marginRight: '8px' }} /> {country.label}</>
  }));

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
  };

  return (
    <div>
    <div className='contact'  style={{maxWidth: '95%', margin: 'auto', padding: '20px', border: '15px solid #ccc', borderRadius: '8px'}}>
    <div  style={{ maxWidth: '60%', margin: 'auto', padding: '5%', fontFamily: 'Arial, sans-serif', }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Fill in details to book free Consultation</h2>
      <form>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
          <input type="text" placeholder="First Name" style={{ width: '40%', padding: '10px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc' }} />
          <input type="text" placeholder="Last Name" style={{ width: '45%', padding: '10px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc' }} />
        </div>
        <div style={{ marginBottom: '10px', paddingTop: '20px' }}>
          <h3 style={{ marginBottom: '10px' }}>Contact Details</h3>
          <input type="email" placeholder="Your E-mail address" style={{ width: '60%', padding: '10px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '10px' }} />
          <div style={{ display: 'flex' }}>
            <input type="text" placeholder="+91" style={{ width: '10%', padding: '10px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc' }} />
            <input type="text" placeholder="Your Phone Number" style={{ width: '56%', padding: '10px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc', marginLeft: '20px' }} />
          </div>
        </div>
        <div style={{ marginBottom: '10px' , display: 'flex', paddingTop: '20px'}}>
          <h3 style={{ marginBottom: '10px' }}>Choose Country</h3>
          <Select
            options={options}
            value={country}
            onChange={handleCountryChange}
            placeholder="Select Country"
            styles={{
              control: (provided) => ({
                ...provided,
                padding: '2px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                marginLeft: '80px',
                marginTop: '10px'
              })
            }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <input type="text" placeholder="Company Name" style={{ width: '100%', padding: '10px', fontSize: '16px', borderRadius: '4px', border: '1px solid #ccc' }} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <button type="submit" style={{ padding: '10px 40px', fontSize: '16px', backgroundColor: 'rgba(55, 54, 86, 1)', color: 'white', border: 'none', cursor: 'pointer', borderRadius: '50px' }}>Submit</button>
        </div>
      </form>
    </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Contact;
